import {DesignerEntity} from './designer.entity';

export enum DateType {
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'dateTime'
}

export class DisplayTransformation extends DesignerEntity {
  public transformScript?: string;
  public backwardTransformScript?: string;
  public needsConversionScript?: string;
  public decimalPlaces?: number;
  public symbol?: string;
  public defaultValue?: string;
  public dateType?: DateType;
  public showSeparator?: boolean;
}



