import {_} from '@wspsoft/underscore';
import {AbstractJsCompiler} from '../../compiler/js-compiler';
import {CriteriaQueryGroupJson} from '../../criteria/json/criteria-query-group-json';

export interface PugElement {
  tagName?: string;
  id?: string;
  indent: number;
  originalValue: string;
  attributes: { [attr: string]: any };
}

export abstract class PugUtil {
  private static absoluteHrefRegex: RegExp = new RegExp(/href='\/(.*?)'/, 'gm');
  private static srcRegex: RegExp = new RegExp(/src='\/(.*?)'/, 'gm');
  private static hrefRegex: RegExp = new RegExp(/href='(.*?)'/, 'gm');
  private static oneElementRegex: RegExp = new RegExp(/^(\s*)one-([\w\-.#]*\()/, 'gm');

  /**
   * search for pug elements with specific tag
   */
  public static getElementsByTag(pug: string, tag: string): PugElement[] {
    const regex = new RegExp(`^\\s*${tag}.*$`, 'gm');
    const result = [];
    for (const match of pug.match(regex) || []) {
      result.push(this.parsePugElement(match));
    }
    return result;
  }

  /**
   * post process grapes html output to its final pug form
   * also keeps track of all used fields for pre loading data on template generation
   */
  public static postProcessTemplatePug(pug: string, e: { html: string; css: string },
                                       compiler: AbstractJsCompiler): { pug: string; recordFields: string[]; widgets: string[] } {
    let recordFields = [];
    const widgets = [];

    // insert grapes generated css
    const headElement = this.getElementsByTag(pug, 'head')[0];
    const actionableMessage = this.getElementsByTag(pug, 'actionablemessage')[0];
    if (actionableMessage?.attributes.code) {
      const message = this.convertActionableMessageToPug(actionableMessage) + '\n';
      pug = pug.replace(actionableMessage.originalValue, message);
    }

    pug = pug.replace(headElement.originalValue, `${headElement.originalValue}
    meta(http-equiv='Content-Type' content='text/html; charset=utf-8')
    style.
      !{css}`);

    // convert loops for pug
    for (const loop of this.getElementsByTag(pug, 'loop')) {
      const recordVariable = loop.attributes.recordpath || loop.attributes.recordvariable;
      const recordFieldVariable = loop.attributes.multi === 'true' ? `${recordVariable}` : `${recordVariable}.${loop.attributes.field}`;
      recordFields.push(recordFieldVariable);

      if (loop.attributes.query) {
        loop.attributes.query = JSON.parse(loop.attributes.query);
        this.getAllAccessFields(loop.attributes.query, recordFields, recordFieldVariable);
      }

      recordFields = this.uniqFields(recordFields);
      pug = this.convertLoopElementToPug(pug, loop, compiler);
    }

    // convert if blocks
    for (const ifElement of this.getElementsByTag(pug, 'if')) {
      if (ifElement.attributes.query) {
        ifElement.attributes.query = JSON.parse(ifElement.attributes.query);
        pug = this.convertIfElementToPug(pug, ifElement, compiler);
        this.getAllAccessFields(ifElement.attributes.query, recordFields, ifElement.attributes.recordpath || ifElement.attributes.recordvariable);
      }
    }


    // convert record links
    for (const recordLink of this.getElementsByTag(pug, 'a')) {
      if (recordLink.attributes.href) {
        pug = this.convertLinkElementToPug(pug, recordLink);
      }
      if (recordLink.attributes.recordpath) {
        recordFields.push(`${recordLink.attributes.recordpath}.${recordLink.attributes.field}`);
      }
    }

    // convert table bodies
    for (const tableBody of this.getElementsByTag(pug, 'tbody')) {
      if (tableBody.attributes.recordvariable) {
        if (tableBody.attributes.query) {
          tableBody.attributes.query = JSON.parse(tableBody.attributes.query);
          this.getAllAccessFields(tableBody.attributes.query, recordFields, tableBody.attributes.recordpath || tableBody.attributes.recordvariable);
        }

        pug = this.convertTableBodyElementToPug(pug, tableBody, compiler);
        const recordVariable = tableBody.attributes.recordpath || tableBody.attributes.recordvariable;
        recordFields.push(tableBody.attributes.multi === 'true' ? `${recordVariable}` : `${recordVariable}.${tableBody.attributes.field}`);
        recordFields = this.uniqFields(recordFields);
      }
    }

    // convert table bodies
    for (const widget of this.getElementsByTag(pug, 'one-widget')) {
      if (widget.attributes.widgetid) {
        pug = this.convertOneWidget(pug, widget);
        widgets.push(widget.attributes.widgetid);
      }
    }

    // convert button
    for (const button of this.getElementsByTag(pug, 'one-button')) {
      if (button.attributes.button) {
        pug = this.convertOneButton(pug, button);
      }
    }

    // convert all field accesses and wrap with translator
    for (const interpolation of this.getElementsByAttribute(pug, 'record[-]?variable')) {
      pug = this.convertFieldAccess(pug, interpolation);
      const variable = interpolation.attributes['record-path'] || interpolation.attributes['record-variable']
        || interpolation.attributes.recordpath || interpolation.attributes.recordvariable;
      const field = interpolation.attributes.field;
      if (field) {
        recordFields.push(`${variable}.${field}`);
        recordFields = this.uniqFields(recordFields);
      }
    }

    // convert all relative urls to absolute urls with interpolation
    pug = pug.replace(this.absoluteHrefRegex, 'href=`${serverAdd}/$1`')
      .replace(this.srcRegex, 'src=`${serverAdd}/$1`')
      // allow interpolation of any href
      .replace(this.hrefRegex, 'href=`$1`')
      .replace(this.oneElementRegex, '$1native-$2');

    return {recordFields, pug, widgets};
  }

  /**
   * parse on line of pug code as element
   */
  private static parsePugElement(s: string): PugElement {
    const originalValue = s.trim();
    const result: PugElement = {
      attributes: {},
      originalValue,
      indent: s.length - originalValue.length
    };

    // extract attributes and tag of element, is in form: tag(fields)
    const match = new RegExp(/(.*?)\((.*)\)/gm).exec(result.originalValue);
    if (match) {
      const tagAndId = match[1];
      const attributes = match[2];

      // check if it has an id and split
      if (tagAndId.includes('#')) {
        const [tag, id] = tagAndId.split('#');
        result.tagName = tag;
        result.id = id;
      } else {
        result.tagName = tagAndId;
      }

      if (attributes) {
        for (const attribute of attributes.split(', ')) {
          // match attributes, may be in form: "data-attr=value", "data-attr-attr=value" or "attr=value"
          const attributeMatch = new RegExp(/((?:\w+[-]?)+)(?:=(.*))?/g).exec(attribute);
          result.attributes[attributeMatch[1].replace('data-', '')] = _.trim(attributeMatch[2] ?? '', '\'');
        }
      }
    }

    return result;
  }

  /**
   * search for pug element with specific attribute
   */
  private static getElementsByAttribute(pug: string, tag: string): PugElement[] {
    const regex = new RegExp(`^\s*[\w.#]*(.*${tag}.*).*$`, 'gm');
    const result = [];
    for (const match of pug.match(regex) || []) {
      result.push(this.parsePugElement(match));
    }
    return result;
  }

  /**
   * convert temp fake loop from grapes to pug code
   */
  private static convertLoopElementToPug(pug: string, element: PugElement, compiler: AbstractJsCompiler): string {
    const loopPug = this.getLoopPug(element, compiler);
    return pug.replace(element.originalValue, loopPug);
  }

  /**
   * convert table body with loop attributes to pug code
   */
  private static convertTableBodyElementToPug(pug: string, element: PugElement, compiler: AbstractJsCompiler): string {
    return pug.replace(element.originalValue, `${element.originalValue}
${' '.repeat(element.indent)}${this.getLoopPug(element, compiler)}`);
  }

  /**
   * convert temp fake if from grapes to pug code
   */
  private static convertIfElementToPug(pug: string, element: PugElement, compiler: AbstractJsCompiler): string {
    const js = compiler.compile(element.attributes.query).replace(/await /g, '').replace(/\n/g, ' ');
    // eslint-disable-next-line max-len
    const ifPug = `if scriptExecutor.runScript('${js}', {...data, record: ${element.attributes.recordvariable}, user}, undefined, 'HtmlTemplate:if', false, true).result`;
    return pug.replace(element.originalValue, ifPug);
  }

  /**
   * convert the #{} expression in recordLinks to working markup for pug
   */
  private static convertLinkElementToPug(pug: string, element: PugElement): string {
    return pug.replace(new RegExp(`href='(.*?=)#{(${element.attributes.dotwalk}.id})'`, 'gm'),
      'href=`$1\${$2`');
  }

  /**
   * convert record field access to translated output
   */
  private static convertFieldAccess(pug: string, element: PugElement): string {
    const quillAttribute = element.attributes['record-variable'];
    const variable = quillAttribute || element.attributes.recordvariable;
    const field = element.attributes.field;
    const quillContent = quillAttribute ? '([|\\s*])' : '()#';
    const replacedWithTranslation = pug.replace(new RegExp(`(?!=')${quillContent}{${variable}(.${field})?}(?!')`, 'm'),
      `$1#{translate.translateObjectValue(${variable}, '${field || 'representativeString'}', language, undefined, undefined, true, timezone)}`);

    if (quillAttribute) {
      return replacedWithTranslation
        .replace('.mention', '')
        .replace(/\s*span.ql-mention-denotation-char #/m, '');
    } else {
      // in case of attribute interpolation use es6 template strings
      return replacedWithTranslation.replace(new RegExp(`='#{(${variable}(.${field})?)}'`, 'gm'), '=`\${JSON.stringify($1)}`');
    }
  }

  private static convertActionableMessageToPug(element: PugElement): string {
    return `
    section(itemscope='' itemtype='http://schema.org/SignedAdaptiveCard')
      meta(itemprop='@context' content='http://schema.org/extensions')
      meta(itemprop='@type' content='SignedAdaptiveCard')
      div(itemprop='signedAdaptiveCard' style='mso-hide:all;display:none;max-height:0px;overflow:hidden;')
        script(type='application/adaptivecard+json').
          ${element.attributes.code}`;
  }

  private static convertOneWidget(pug: string, widget: PugElement): string {
    return pug.replace(widget.originalValue, widget.originalValue.replace(/widget='.*'/,
      // eslint-disable-next-line max-len
      `widget=\`$\{JSON.stringify(widgetData['${widget.attributes.widgetid}'].widget)}\` data=\`$\{JSON.stringify(widgetData['${widget.attributes.widgetid}'].data)\}\``));
  }

  private static convertOneButton(pug: string, button: PugElement): string {
    return pug.replace(button.originalValue,
      button.originalValue.replace(/value='#{(.*)}'/, 'value=`$\{$1\}`').replace(/formid='#{(.*)}'/, 'formid=`$\{$1\}`'));
  }

  private static getLoopPug(element: PugElement, compiler: AbstractJsCompiler): string {
    const loopExpression = element.attributes.field ? `${element.attributes.recordvariable}.${element.attributes.field}` : element.attributes.recordvariable;
    let filter = '';
    if (element.attributes.query) {
      const js = compiler.compile(element.attributes.query).replace(/await /g, '').replace(/\n/g, ' ');
      filter = `.filter(x => scriptExecutor.runScript('${js}', {...data, record: x, user}, undefined, 'HtmlTemplate:Loop:filter').result)`;
    }
    return `each ${element.attributes.loopvariable} in ${loopExpression}${filter}`;
  }

  /**
   * remove duplicated or included dotwalks
   */
  private static uniqFields(fields: string[]): string[] {
    return _.uniqWith(_.orderBy(fields, 'length', 'desc'), (a, b) => b.startsWith(a));
  }

  /**
   * search for all field accesses in criteria
   */
  private static getAllAccessFields(group: CriteriaQueryGroupJson, recordFields: string[], recordVariable: string): void {
    for (const cond of group.whereCondition) {
      recordFields.push(`${recordVariable}.${cond.columnName}`);
      recordFields = this.uniqFields(recordFields);
    }
    for (const subGroup of group.groups) {
      this.getAllAccessFields(subGroup, recordFields, recordVariable);
    }
  }
}
