import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {AspectRatio, Attachment} from '@wspsoft/frontend-backend-common';
import {environment} from '../../../../config/environments/environment';

@Component({
  selector: 'ui-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent {
  public environment: typeof environment = environment;
  public staticPath: string = (window as any).staticPath;
  @Input()
  public editEnabled: boolean = false;
  @Input()
  public aspectRatio: AspectRatio = AspectRatio.square;
  @Output()
  public activeIndexChange: EventEmitter<number> = new EventEmitter();
  @ContentChild('header', {static: true})
  public header: TemplateRef<any>;
  @ContentChild('thumbnail', {static: true})
  public thumbnail: TemplateRef<any>;
  public convertedImages: AttachmentUrl[];
  public hasImages: boolean;
  public numVisible: number = 3;

  public constructor(public element: ElementRef, public cdr: ChangeDetectorRef) {
  }

  private pactiveIndex: number;

  @Input()
  public get activeIndex(): number {
    return this.pactiveIndex;
  }

  public set activeIndex(value: number) {
    this.pactiveIndex = value;
    this.activeIndexChange.emit(value);
  }

  @Input()
  public set attachments(attachments: Attachment[]) {
    if (attachments) {
      this.convertImages(attachments);
    }
  };

  public get aspectRatioDimension(): string {
    switch (this.aspectRatio) {
      case AspectRatio.circle:
      case AspectRatio.square:
      default:
        return 'width=300&height=300&fit=contain';
      case AspectRatio.shitInShitOut:
        return 'height=300&fit=contain';
    }
  }

  public convertImages(attachments: Attachment[]): void {
    const convertedImages = [];
    for (const image of attachments) {
      if (image.mimeType.startsWith('image/')) {
        convertedImages.push({
          attachment: image,
          url: `${environment.serverAdd}/files/${image.id}`,
        });
      }
    }
    this.hasImages = !!convertedImages.length;
    if (!convertedImages.length) {
      convertedImages.push({
        attachment: {},
        url: `${environment.serverAdd}${(window as any).staticPath}/images/fa-images.svg`
      });
    }

    this.convertedImages = convertedImages;
  }
}

interface AttachmentUrl {
  attachment: Attachment;
  url: string;
}
