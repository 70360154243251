import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {NgxUiLoaderService, SPINNER} from 'ngx-ui-loader';
import {SettingsService} from '../../../../api';
import {environment} from '../../../../config/environments/environment';

export const routeLoader = 'routeLoader';
export const initialLoader = 'initialLoader';
export const taskId = '$_router_loader';

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  public environment: typeof environment = environment;
  public staticPath: string = (window as any).staticPath;
  @Input()
  public text: string;
  @Input()
  public loaderId: string;
  @Input()
  public progressBar: boolean = false;
  @Input()
  public loadSettings: boolean = true;
  public SPINNER: typeof SPINNER = SPINNER;
  public logoLoader: boolean = false;
  public logo: string = 'WSPone.svg';
  public progressBarColor: string = 'var(--primary)';
  private fallbackTimeout: number;

  public constructor(private settingsService: SettingsService, private loader: NgxUiLoaderService,
                     private zone: NgZone, private cdr: ChangeDetectorRef) {
    const stopLoader = this.loader.stopLoader.bind(this.loader);
    this.loader.stopLoader = (loaderId: string, taskId1?: string) => {
      stopLoader(loaderId, taskId1);
      this.cdr.detectChanges();
    };
  }

  public ngOnInit(): void {
    const platformSettings = this.settingsService.platformSettings;
    this.logoLoader = !!platformSettings?.loaderLogoId;
    this.logo = platformSettings?.loaderLogoId;

    if (this.loaderId === initialLoader) {
      this.loader.startLoader(initialLoader, taskId);

      // emergency timeout when zone is stable before we could subscribe
      // @ts-ignore
      this.fallbackTimeout = setTimeout(() => {
        this.zone.run(() => this.stopLoader());
      }, 5000);

      const subscription = this.zone.onStable.subscribe(() => {
        subscription.unsubscribe();

        this.zone.run(() => this.stopLoader());
      });
    }
  }

  private stopLoader(): void {
    this.loader.stopLoader(initialLoader, taskId);
    clearTimeout(this.fallbackTimeout);
  }
}
