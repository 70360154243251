<ui-input-wrapper [disable]="disable" [hasInlineLabel]="true" [label]="label" [renderInputGroup]="renderInputGroup" [require]="require">
  <ng-container *ngIf="!multiple && imageCropper">
    <ui-image-cropper (onCrop)="cropFile(); showCropper=false" *ngIf="value && showCropper" [(ngModel)]="croppedFile" [image]="originalFile"></ui-image-cropper>

    <figure *ngIf="!showCropper || !value" [ngClass]="{'one-avatar--empty': !value}" class="one-avatar one-avatar--original">
      <img *ngIf="value" class="one-avatar__content" defaultImage="{{environment.serverAdd}}{{staticPath}}/images/digits.gif"
           lazyLoad="{{environment.serverAdd}}/files/{{value.id}}?width=300&height=300&rev={{value._rev}}">

      <div class="one-avatar__overlay">
        <ng-container [ngTemplateOutlet]="uploader"></ng-container>
        <ui-button (clicked)="showCropper = true; checkForSvg(); $event.cb()" *ngIf="!showCropper && value"
                   class="one-avatar__button one-avatar__button--cropper" icon="fa fa-fw fa-crop" label="{{'FileUpload.Crop' | translate}}"
                   type="icon-only"></ui-button>
      </div>
    </figure>
  </ng-container>

  <ng-container *ngIf="!multiple && !imageCropper">
    <ng-container *ngIf="value?.persisted && !value.quarantined else fileDescription">
      <a [ngClass]="{
        'quarantined': value.quarantined,
      }" href="{{environment.serverAdd}}/files/{{value.id}}?placeholder=missing" target="_blank">
        <ng-container [ngTemplateOutlet]="fileDescription"></ng-container>
      </a>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="multiple">
    <ul *ngIf="value && value.length" class="p-fileupload-multiple-container">
      <ng-container *ngFor="let val of value">
        <li [ngClass]="{
        'p-fileupload-token--new':!val.persisted,
        'p-fileupload-token': val.persisted,
        }">
          <ng-container *ngIf="val.persisted">
            <a class="p-fileupload-link" href="{{environment.serverAdd}}/files/{{getAttachmentId(val)}}?placeholder=missing" target="_blank">
              <i>{{ val.fileName }}</i>
            </a>
            <span (click)="deleteOneFile(val)" *ngIf="!disable" class="p-fileupload-token-icon">
              <span class="fa fa-fw fa-times"></span>
            </span>
          </ng-container>
          <ng-container *ngIf="!val.persisted">
            <i>{{ val.fileName }}</i>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <ng-container *ngIf="!imageCropper" [ngTemplateOutlet]="uploader"></ng-container>
</ui-input-wrapper>

<ng-template #fileDescription>
  <span [ngClass]="{'one-helper--quarantined':value?.quarantined}"
        pTooltip="{{value?.quarantined ? ('CustomMessages.Quarantine.QuarantinedAttachment' | translate) : ''}}" tooltipPosition="top">
    <i *ngIf="value?.quarantined" class="fa fa-fw fa-virus"></i>
    <i>{{ value ? value.fileName : disable ? ('FileUpload.None' | translate) : '' }}</i>
  </span>
</ng-template>

<ng-template #uploader>
  <p-fileUpload #fu (onRemove)="onRemove(($event).file)" (onSelect)="showMessage(fu.msgs)" (uploadHandler)="uploadFile($event)" [accept]="fileType"
                [auto]="true" [chooseIcon]="chooseIcon" [customUpload]="true" [disabled]="disable"
                [invalidFileLimitMessageSummary]="'FileUpload.LimitExceeded' | translate"
                [invalidFileSizeMessageSummary]="'FileUpload.SizeExceeded' | translate" [invalidFileTypeMessageSummary]="'FileUpload.InvalidType' | translate"
                [maxFileSize]="maxFileSize()" [multiple]="multiple" [ngClass]="{
                  'p-fileupload--with-dropzone': showDropzone(),
                }" [showDelay]="150" [style]="styleData" chooseLabel="{{tooltip | translate}}" mode="advanced"
                pTooltip="{{showDropzone() ? '' : tooltip | translate}}" styleClass="{{disable ? 'p-hidden' : showDropzone() ? '' :
                'one-button one-button--icon-only'}}" tooltipPosition="top"></p-fileUpload>
  <ui-button (clicked)="deleteFile($event.cb)" *ngIf="value && !disable" [type]="['icon-only', 'red']" icon="fa fa-fw fa-trash"
             label="{{'FileUpload.Delete' | translate}}"></ui-button>
</ng-template>
