import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CustomInput} from '../data/custom-input';

@Component({
  selector: 'ui-slider',
  templateUrl: './ui-slider.component.html',
  styleUrls: ['./ui-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiSliderComponent extends CustomInput<any> implements OnInit {
  @Input()
  public values: number[];
  @Input()
  public min: number;
  @Input()
  public max: number;

  public ngOnInit(): void {
    if (!this.values && (this.min || this.min === 0) && this.max) {
      this.values = [this.min, this.max];
    } else if (!this.values) {
      this.values = [0, 100];
    }
  }

  public emitOnFilter(event: any): void {
    this.onChange.emit(event);
    this.cdr.detectChanges();
  }
}
