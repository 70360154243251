import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  Attribute,
  AttributeComputationType,
  CriteriaOperator,
  DateType,
  DisplayButtonType,
  EntityModel,
  Field,
  FilterType,
  ListSelectionMode,
  OneTableState,
  Utility
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ConfirmationService, FilterMetadata, LazyLoadEvent, MenuItem, MessageService, SelectItem, SortMeta, TableState} from 'primeng/api';
import {Table} from 'primeng/table';
import {CircularService, ModelService, TypeService} from '../../../../../../api';
import {DatatableAction} from '../../../../entities/datatable-action';
import {OneConfirmService} from '../../../../service/one-confirm.service';
import {OneDialogService} from '../../../../service/one-dialog.service';
import {StructureService} from '../../../../service/structure.service';
import {UiUtil} from '../../../../util/ui-util';
import {TableComponent} from '../../table.component';
import {ColumnPickerComponent} from '../column-picker/column-picker.component';
import {DatatableColumn, ReorderEvent} from '../datatable/datatable.component';

const handleRowRightClick = Table.prototype.handleRowRightClick;
// tslint:disable-next-line
Table.prototype.handleRowRightClick = function (event: any): boolean {
  if (event.originalEvent.ctrlKey) {
    return true;
  }
  event.originalEvent.stopPropagation();

  handleRowRightClick.bind(this)(event);
};


@Component({
  selector: 'ui-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss', '../datatable/datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableComponent extends TableComponent implements OnInit, AfterViewInit {
  public static readonly FUNCTION_GETTER: string[] = [AttributeComputationType.FUNCTION, AttributeComputationType.GETTER];
  public static readonly NOT_GROUPABLE_ATTRIBUTES: string[] = [
    'Any',
    'Code',
    'I18n',
    'JSON',
    'LargeBinary',
    'LargeJSON',
    'LargeText',
    'MaybeBlob',
    'VariablesData'
  ];
  public ButtonType: typeof DisplayButtonType = DisplayButtonType;
  public ListSelectionMode: typeof ListSelectionMode = ListSelectionMode;
  public Utility: typeof Utility = Utility;
  @Input()
  public dataKey: string = 'name';
  @Input()
  public styleClass: string = '';
  @Input()
  public lazy: boolean = true;
  @Input()
  public columnToggler: boolean;
  @Input()
  public allowPdfExport: boolean = true;
  @Input()
  public allowXlsExport: boolean = true;
  @Input()
  public allowJsonExport: boolean = true;
  @Input()
  public allowCsvExport: boolean = true;
  @Input()
  public resizeable: boolean = false;
  @Input()
  public compact: boolean = false;
  @Input()
  public columns: DatatableColumn[];
  @Input()
  public availableColumns: DatatableColumn[];
  @Input()
  public multiSortMeta: SortMeta[];
  @Input()
  public entityMeta: EntityModel;
  @Input()
  public rowGroupMode: string;
  @Input()
  public groupRowsBy: string;
  @Input()
  public sortField: string;
  @Input()
  public canUseQueryBuilder: boolean = true;
  @Input()
  public enableGrouping: boolean = false;
  @Input()
  public fixedGrouping: boolean = false;
  @Output()
  public onFilter: EventEmitter<{ field: string; value: any; operator: CriteriaOperator }> = new EventEmitter();
  @Output()
  public onContextFieldSelection: EventEmitter<string> = new EventEmitter();
  @Output()
  public onRowReorder: EventEmitter<ReorderEvent> = new EventEmitter();
  @Output()
  public onStateSave: EventEmitter<TableState> = new EventEmitter();
  @Output()
  public onColumnChange: EventEmitter<OneTableState> = new EventEmitter();
  @Output()
  public onEditCancel: EventEmitter<any> = new EventEmitter();
  @Output()
  public onEditSave: EventEmitter<any> = new EventEmitter();
  public booleanFilters: SelectItem[] = [];
  @ViewChild(Table, {static: false})
  public table: Table;
  @ViewChild('columnPicker')
  public columnPicker: ColumnPickerComponent;
  public tableState: TableState;
  public load: ($event: LazyLoadEvent & { count: boolean }) => Promise<any[]>;
  public showFilters: boolean = true;
  public FilterType = FilterType;
  public DateType = DateType;
  public viewInitReady: boolean = false;
  @HostBinding('class.ui-datatable')
  private cssClass: boolean = true;
  private granularities: { [key: string]: any } = {};
  private dateGranularity: (MenuItem & { value: string })[] = [
    {
      icon: 'fas fa-calendar', value: 'y', label: this.translate.instant('Calendar.Granularity.Year.Value')
    },
    {
      icon: 'fas fa-calendar-alt', value: 'm', label: this.translate.instant('Calendar.Granularity.Month.Value')
    },
    {
      icon: 'fas fa-calendar-day', value: 'd', label: this.translate.instant('Calendar.Granularity.Day.Value')
    },
    {
      icon: 'far fa-clock', value: 'h', label: this.translate.instant('Calendar.Granularity.Hour.Value')
    },
    {
      icon: 'fas fa-stopwatch', value: 'i', label: this.translate.instant('Calendar.Granularity.Minute.Value')
    }];

  public constructor(translate: TranslateService, confirmationService: ConfirmationService, cdr: ChangeDetectorRef, protected circularService: CircularService,
                     messageService: MessageService, public typeUtility: TypeService, oneConfirmService: OneConfirmService,
                     protected modelService: ModelService, private oneDialogService: OneDialogService, protected structureService: StructureService) {
    super(translate, confirmationService, messageService, oneConfirmService, cdr);
  }

  public get globalFilterFields(): string[] {
    return this.columns.map((x) => x.field);
  }

  public get hasEditAction(): boolean {
    return super.hasEditAction && _.some(this.columns, 'editable');
  }

  public get hasAnyColumnAction(): boolean {
    return this.hasDeleteAction || (_.some(this.defaultActions, o => o === DatatableAction.CUSTOM) && !!this.tRowActions) || this.hasEditAction;
  }

  public get hasEditAllAction(): boolean {
    return this.hasEditAction && _.some(this.values, v => !this.table.isRowEditing(v));
  }

  public get hasSaveCancelAllAction(): boolean {
    return this.hasEditAction && _.some(this.values, v => this.table.isRowEditing(v));
  }

  public get isResizable(): boolean {
    return this.resizeable && !this.structureService.mainStructure?.isViewportTablet;
  }

  public get frozenWidth(): string {
    let width = 0;
    if (this.hasAnyColumnAction) {
      width = 60 * 2; // 2 buttons with 60ox each
    }
    return width + 'px';
  }

  /**
   * If the Layout has any option for an export, enable the export btn
   *
   */
  public get hasExportAction(): boolean {
    return this.allowCsvExport || this.allowPdfExport || this.allowXlsExport || this.allowJsonExport;
  }

  public get state(): TableState {
    const stateString = this.table.getStorage().getItem(this.table.stateKey);
    if (stateString) {
      return JSON.parse(stateString);
    }
  }

  public get hasPages(): boolean {
    return this.totalRecords > this.rows;
  }

  public getAdditionalRenderData(col: DatatableColumn): { showSeparator?: boolean } {
    if (col.transformationEntity) {
      return {
        showSeparator: col.transformationEntity.showSeparator
      };
    }
    return undefined;
  }

  public canDelete(data: any): boolean {
    return this.circularService.sessionService.currentUser.checkClientSidePermission(data, 'DELETE', null);
  }

  public canEdit(data: any): boolean {
    return this.circularService.sessionService.currentUser.checkClientSidePermission(data, 'UPDATE', null);
  }

  public async doLoad($event: LazyLoadEvent): Promise<any[]> {
    try {
      this.onBeforeLoad.emit($event);
      if (this.list?.load) {
        // have to await because to correctly reset the flag isRefreshing
        return await this.list.load.apply(this.list, [$event]);
      }
    } finally {
      this.onAfterLoad.emit($event);
    }
  }


  // overwrite default things (not working for our use case)
  // eslint-disable-next-line
  public ngAfterViewInit(): void {
    this.viewInitReady = true;
  }


  @Input()
  public rowTrackBy: TrackByFunction<any> = (index) => index;

  public reset(cb: () => void = () => undefined): void {
    this.confirmationService.confirm({
      key: 'resetConfirm',
      accept: () => {
        this.messageService.add({
          severity: 'success',
          summary: '',
          detail: this.translate.instant('List.Reset.ResetMessage')
        });
        this.table.filters = {};
        this.table.reset();
        this.oneConfirmService.confirmCallback();
        this.columnPicker.displayColumnToggler = false;
        this.tableState = this.state;
        this.onReset.emit();
        cb();
      },
      reject: () => {
        this.oneConfirmService.confirmCallback();
        cb();
      }
    });
  }

  public ngOnInit(): void {
    // prime ng bug with empty multi sort meta
    if (!this.lazy && this.multiSortMeta?.length === 0) {
      this.multiSortMeta = undefined;
    }
    this.rowsPerPageOptions = _.sortBy(_.uniq([...this.rowsPerPageOptions, this.rows]));

    this.booleanFilters = [
      {
        label: this.translate.instant('RawData.All'),
        value: null
      }, {
        label: this.translate.instant('RawData.true'),
        value: true
      }, {
        label: this.translate.instant('RawData.false'),
        value: false
      }
    ];

    this.setupContextMenu();
    if (this.customActions) {
      this.addCustomActions(this.customActions);
    }

    if (this.list?.load) {
      this.load = _.debounce(this.doLoad, 100).bind(this);
    }
  }

  /**
   * enabling edit mode for all rows on page.
   */
  public enableEditForAll(): void {
    for (const value of this.values) {
      this.table.initRowEdit(value);
    }
  }

  /**
   * saves all rows with enabled row edit and disabling edit mode for all rows on page.
   * the dirty flag will be checked in onEditSave
   */
  public saveAllEdited(): void {
    const valuesToUpdate: any[] = [];
    for (const value of this.values) {
      if (this.table.isRowEditing(value)) {
        this.table.saveRowEdit(value, this.table.el.nativeElement);
        valuesToUpdate.push(value);
      }
    }
    if (valuesToUpdate.length) {
      this.onEditSave.emit(valuesToUpdate);
    }
  }

  /**
   * cancels all rows with enabled row edit and disabling edit mode for all rows on page.
   */
  public cancelAllEdited(): void {
    for (const value of this.values) {
      if (this.table.isRowEditing(value)) {
        this.table.cancelRowEdit(value);
        this.onEditCancel.emit(value);
      }
    }
  }

  /**
   * calculates the shown Icon depending on the current granularity setting, defaults to d
   * @param {DatatableColumn} col
   * @returns {string} the string for the matching Icon
   */
  public getDateGranularityIcon(col: DatatableColumn): string {
    // @ts-ignore
    return _.find(this.dateGranularity, {value: this.table?.filters[col.field]?.granularity || 'd'}).icon;
  }

  /**
   * Creates the menu items for granularity selection and adds the corresponding Value of y,m,d etc.
   * @param {DatatableColumn} col
   * @returns {(MenuItem & {value: string})[]}
   */
  public getDateGranularityMenu(col: DatatableColumn): (MenuItem & { value: string })[] {
    if (this.granularities[col.field]) {
      return this.granularities[col.field];
    }

    const self = this;
    const command = function (this: MenuItem & { value: string }): void {
      // @ts-ignore
      self.table.filters[col.field].granularity = this.value;
      self.cdr.detectChanges();
      self.refresh();
    };
    switch (col.transformationEntity.dateType) {
      case DateType.TIME:
        return this.granularities[col.field] = _.cloneDeep(this.dateGranularity).filter(x => (x.value === 'i' || x.value === 'h'))
          .map(m => {
            m.command = command;
            return m;
          });
      case DateType.DATETIME:
        return this.granularities[col.field] = _.cloneDeep(this.dateGranularity).map(m => {
          m.command = command;
          return m;
        });
      default:
        return this.granularities[col.field] = _.cloneDeep(this.dateGranularity).filter(x => !(x.value === 'i' || x.value === 'h'))
          .map(m => {
            m.command = command;
            return m;
          });
    }
  }

  public colSortOrder(field: string): number {
    const t = _.find(this.multiSortMeta, {field});
    return t ? t.order : 0;
  }

  public filterState(filterName: string): FilterMetadata {
    if (this.tableState && this.tableState.filters && (filterName in this.tableState.filters)) {
      return (this.tableState.filters[filterName]) as FilterMetadata;
    }
    return {};
  }

  public refresh(clearSelection?: boolean, externalRefresh: boolean = true): void {
    this.onRefresh.emit(externalRefresh);
    if (clearSelection) {
      this.selection = [];
      this.table.selection = [];
    }
    this.table?._filter();
  }

  public modifyStateSave(state: TableState, clearSelection?: boolean): void {
    this.tableState = state;
    if (!this.hasSelectAction && this.tableState && this.pselection || clearSelection) {
      delete this.tableState.selection;
      this.selection = [];
    }
    sessionStorage.setItem(this.name, JSON.stringify(this.tableState));

    this.onStateSave.emit(this.tableState);
  }

  /**
   * when restoring the tableState we might want to clear the selection made previously
   */
  public modifyStateRestore(state: TableState): void {
    this.tableState = state;
    if (!this.hasSelectAction && this.tableState) {
      delete this.tableState.selection;
      this.selection = null;
    }
  }

  public aggregate({field, aggregation}: DatatableColumn): number {
    const agg = Utility.aggregate(field, aggregation, this.values);
    return isNaN(agg) ? null : agg;
  }

  /**
   * converts our types for prime ng col filter
   */
  public getColumnFilterType(s: string): string {
    switch (s) {
      case 'string':
      case 'largetext':
      case 'code':
      case 'i18n':
      case 'kolibrientity':
        return 'text';
      case 'number':
        return 'numeric';
      case 'date':
        if (!this.lazy) {
          return 'numeric';
        }
        return 'date';
      default:
        if (!this.lazy) {
          return 'text';
        }
        return s;
    }
  }

  public convertMatchModeToIcon(col: DatatableColumn): string[] {
    const mode = this.filterState(col.field).matchMode || col.defaultFilterOperator || 'startsWith';
    switch (mode) {
      case 'contains':
        return ['fas fa-fw fa-percent'];

      case 'notContains':
        return ['fas fa-fw fa-percent', 'fas fa-fw fa-slash'];

      case 'dateIs':
      case 'equals':
        return ['fas fa-fw fa-equals'];

      case 'dateIsNot':
      case 'notEquals':
        return ['fas fa-fw fa-not-equal'];

      case 'lte':
        return ['fas fa-fw fa-less-than-equal'];
      case 'lt':
      case 'dateBefore':
        return ['fas fa-fw fa-less-than'];

      case 'gte':
        return ['fas fa-fw fa-greater-than-equal'];
      case 'gt':
      case 'dateAfter':
        return ['fas fa-fw fa-greater-than'];

      case 'startsWith':
        return ['fas fa-fw fa-sign-out-alt'];

      case 'endsWith':
        return ['fas fa-fw fa-sign-in-alt'];

    }
  }

  /**
   * do a full table count
   */
  public count(): void {
    const value = this.table.createLazyLoadMetadata();
    value.count = true;
    this.table.onLazyLoad.emit(value);
  }

  /**
   * Sets up the different actions of the setup menu buttons
   */
  public getToolbarButtons(): MenuItem[] {
    const menuButtons = [];
    menuButtons.unshift({
      label: this.translate.instant('List.Reset.Tooltip'),
      icon: 'fas fa-fw fa-eraser',
      visible: true,
      command: ($event) => {
        this.reset($event.cb);
      },
    });

    if (this.columnToggler) {
      menuButtons.unshift({
        label: this.translate.instant('List.ColumnToggler'),
        icon: 'fas fa-fw fa-columns',
        visible: true,
        command: () => {
          this.columnPicker.show();
        }
      });
    }

    if (this.hasExportAction) {
      menuButtons.unshift({
        label: this.translate.instant('List.Export.Tooltip'),
        icon: 'fas fa-fw fa-download',
        visible: true,
        command: () => {
          // @ts-ignore
          this.oneDialogService.show(this.list.entityMeta.name);
        }
      });
    }
    return menuButtons;
  }

  public isColAttributeInteractable(col: DatatableColumn): boolean {
    return !SimpleTableComponent.FUNCTION_GETTER.includes((col.meta as Attribute)?.computed);
  }

  public isColAttributeGroupable(col: DatatableColumn): boolean {
    return !SimpleTableComponent.NOT_GROUPABLE_ATTRIBUTES.includes(col.typeName);
  }

  public hasMinWidth(col: DatatableColumn): boolean {
    const meta = col.meta;
    return this.showFilters && col.filterable && this.isColAttributeInteractable(col) && this.typeUtility.getKolibriType(meta) !== 'attachment' &&
      this.typeUtility.getKolibriType(meta) !== 'date';
  }

  protected setupContextMenu(): void {
    super.setupContextMenu();
    if (!this.lazy || this.onFilter.observed) {
      if (this.canUseQueryBuilder) {
        this.contextMenu.push({
          label: this.translate.instant('List.Actions.FilterIn'),
          icon: 'fas fa-fw fa-plus',
          command: () => {
            if (this.contextFieldSelection) {
              const targetColumn = _.find(this.columns, x => x.field === this.contextFieldSelection);
              if (targetColumn && (targetColumn.filterable !== true || !this.isColAttributeInteractable(targetColumn))) {
                this.messageService.add({
                  severity: 'warn',
                  detail: this.translate.instant('Datatable.ContextMenu.NotFilterable'),
                  summary: ''
                });
                return;
              }
              Utility.doDotWalk(this.contextSelection, this.contextFieldSelection, x => {
                if (targetColumn.typeName === 'I18n') {
                  // @ts-ignore, typing of window sucks
                  x = x?.[this.circularService.sessionService.currentUser.language] || x?.[window.systemLanguage];
                }

                x = this.getIdForDeadValues(targetColumn.meta, x);

                if (this.onFilter.observed) {
                  this.onFilter.emit({
                    field: this.contextFieldSelection,
                    value: x,
                    operator: this.getFilterInOperatorBasedOnValue(x)
                  });
                } else {
                  this.table.filter(x, this.contextFieldSelection, 'equals');
                }
              });
            }
          }
        });
        this.contextMenu.push({
          label: this.translate.instant('List.Actions.FilterOut'),
          icon: 'fas fa-fw fa-minus',
          command: () => {
            if (this.contextFieldSelection) {
              const targetColumn = _.find(this.columns, x => x.field === this.contextFieldSelection);
              if (targetColumn && (targetColumn.filterable !== true || !this.isColAttributeInteractable(targetColumn))) {
                this.messageService.add({
                  severity: 'warn',
                  detail: this.translate.instant('Datatable.ContextMenu.NotFilterable'),
                  summary: ''
                });
                return;
              }
              Utility.doDotWalk(this.contextSelection, this.contextFieldSelection, x => {
                if (targetColumn.typeName === 'I18n') {
                  // @ts-ignore, typing of window sucks
                  x = x?.[this.circularService.sessionService.currentUser.language] || x?.[window.systemLanguage];
                }

                x = this.getIdForDeadValues(targetColumn.meta, x);

                if (this.onFilter.observed) {
                  this.onFilter.emit({
                    field: this.contextFieldSelection,
                    value: x,
                    operator: this.getFilterOutOperatorBasedOnValue(x)
                  });
                } else {
                  this.table.filter(x, this.contextFieldSelection, 'notEquals');
                }
              });
            }
          }
        });
      }
    }
    if (this.enableGrouping && !this.fixedGrouping) {
      this.contextMenu.push({
        label: this.translate.instant('List.GroupBy'),
        id: 'GroupBy',
        visible: true,
        icon: 'fas fa-fw fa-grip-lines',
        command: (): void => {
          if (this.contextFieldSelection) {
            const targetColumn = _.find(this.columns, x => x.field === this.contextFieldSelection);
            if (targetColumn && !this.isColAttributeGroupable(targetColumn)) {
              this.messageService.add({
                severity: 'warn',
                detail: this.translate.instant('Datatable.ContextMenu.NotGroupable'),
                summary: ''
              });
              return;
            }

            this.onContextFieldSelection.emit(this.contextFieldSelection);
          }
        },
      });
      this.contextMenu.push({
        label: this.translate.instant('List.NoGroupBy'),
        id: 'NoGroupBy',
        visible: false,
        icon: 'fas fa-fw fa-align-justify',
        command: (): void => {
          if (this.contextFieldSelection) {
            this.onContextFieldSelection.emit(null);
          }
        },
      });
    }
  }

  private getFilterInOperatorBasedOnValue(value: any): CriteriaOperator {
    if (_.isNull(value)) {
      return CriteriaOperator.IS_NULL;
    }

    if (typeof value !== 'object') {
      return CriteriaOperator.EQUAL;
    }

    if (Array.isArray(value)) {
      return _.isEmpty(value) ? CriteriaOperator.IS_EMPTY : CriteriaOperator.IN;
    }

    return CriteriaOperator.IS;
  }

  private getFilterOutOperatorBasedOnValue(value: any): CriteriaOperator {
    if (_.isNull(value)) {
      return CriteriaOperator.IS_NOT_NULL;
    }

    if (typeof value !== 'object') {
      return CriteriaOperator.NOT_EQUAL;
    }

    if (Array.isArray(value)) {
      return _.isEmpty(value) ? CriteriaOperator.IS_NOT_EMPTY : CriteriaOperator.NOT_IN;
    }

    return CriteriaOperator.IS_NOT;
  }

  /**
   * returns the value itself, or if it's a dead value then return the id
   * @param field the field to check if it is a relation
   * @param value the value to check
   * @private
   */
  private getIdForDeadValues<T>(field: Field, value: T | string): T | string {
    if (Utility.isRelation(field) && UiUtil.isDeadValue(value, this.contextSelection, this.contextFieldSelection)) {
      value = UiUtil.getIdValue(this.contextSelection, this.contextFieldSelection);
    }
    return value;
  }
}
