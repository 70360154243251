import {KolibriEntity} from '../kolibri-entity';
import {ImportController} from './import-controller';

export enum ImportRunState {
  queued = 'queued',
  running = 'running',
  canceled = 'canceled',
  completed = 'completed',
  completedWithErrors = 'completedWithErrors',
  failed = 'failed'
}

export interface ImportRun extends KolibriEntity {
  state: ImportRunState;
  overallRunTime: string;
  completed: string;
  stagingRunTime: string;
  transformRunTime: string;
  importController: ImportController;
  importControllerId: string;
  expiresAt: string;
  error?: string;

  // run stats
  total?: number;
  totalErrors?: number;
  inserts?: number;
  updates?: number;
  ignored?: number;
  skipped?: number;
  faulty?: number;
  rejected?: number;
}
