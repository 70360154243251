import {CriteriaConditionOperatorOnly} from '../criteria/criteria-condition-operator';
import {CriteriaOrderBy} from '../criteria/criteria-order-by';
import {ConditionValueType, CriteriaConditionJson} from '../criteria/json/criteria-condition-json';
import {CriteriaQueryGroupJson} from '../criteria/json/criteria-query-group-json';
import {CriteriaQueryJson} from '../criteria/json/criteria-query-json';
import {AbstractModelService} from '../service/coded/abstract-model.service';
import {AbstractCriteriaBooleanCompiler} from './criteria-boolean-compiler';
import {_} from '@wspsoft/underscore';

export abstract class AbstractCriteriaJsQueryCompiler extends AbstractCriteriaBooleanCompiler {
  protected constructor(modelService: AbstractModelService) {
    super(modelService);
  }

  /**
   * convert criteria condition to a query.addCondition
   */
  private static compileQueryCondition(condition: CriteriaConditionJson, varName: string): string {
    if (condition.operator as any === CriteriaConditionOperatorOnly.SCRIPT) {
      return condition.value;
    }

    let value: string;
    if (condition.scripted === ConditionValueType.SCRIPTED_VALUE) {
      const isAsync = condition.value.includes('await');
      value = `${isAsync ? 'await ' : ''}(${isAsync ? 'async ' : ''}function() {
  ${condition.value}
})()`;
    } else {
      value = JSON.stringify(condition.value);
    }
    return `${varName}.addCondition(${JSON.stringify(condition.columnName)}, ${JSON.stringify(
      condition.operator)}, ${value}, ${condition.or}, undefined, ${JSON.stringify(condition.criteriaFunction)}, ${JSON.stringify(
      condition.options)}, ${JSON.stringify(
      condition.scripted)});`;
  }

  /**
   * convert criteria orderBy to a query.addOrder string
   */
  private static compileQueryOrders(orderBy: CriteriaOrderBy, varName: string): string {
    return `${varName}.addOrder(${JSON.stringify(orderBy.columnName)}, ${JSON.stringify(orderBy.order)}, ${JSON.stringify(
      orderBy.criteriaFunction)});`;
  }

  /**
   * compiles the query to a js string, that prefills a existing query
   */
  protected compileQueryScript(query: CriteriaQueryJson): string {
    const result = [];
    for (const condition of query.orders ?? []) {
      result.push(AbstractCriteriaJsQueryCompiler.compileQueryOrders(condition, 'query'));
    }
    result.push(...this.compileQueryGroup(query, 'query'));

    return result.join('\n');
  }

  /**
   * compile a criteria group to a query.addGroup
   */
  private compileQueryGroup(group: CriteriaQueryGroupJson, varName: string, current: number[] = [1]): string[] {
    const result = [];

    for (const condition of (group.whereCondition || []).filter(c => c.active !== false)) {
      if (!condition.columnName) {
        continue;
      }
      result.push(AbstractCriteriaJsQueryCompiler.compileQueryCondition(condition, varName));
    }

    for (const subGroup of (group.groups || []).filter(c => c.active !== false)) {
      if (_.isNullOrEmpty(subGroup)) {
        // prevent empty groups to be added
        continue;
      }
      const subGroupVar = `group${current[0]++}`;
      result.push(`const ${subGroupVar} = ${varName}.addGroup(${subGroup.useOr})`);
      result.push(...this.compileQueryGroup(subGroup, subGroupVar, current));
    }

    return result;
  }
}
