import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  CriteriaQueryJson,
  DynamicContent,
  DynamicContentType,
  Entity,
  KolibriEntity,
  ListLayout,
  PrefQuery,
  ScriptParams
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import * as hash from 'object-hash';
import {MessageService} from 'primeng/api';
import {CircularService, ModelService} from '../../../api';
import {UiUtil} from '../util/ui-util';
import {DynamicContentService} from './dynamic-content.service';
import {LayoutConditionService} from './layout-condition.service';
import {MultiTabSynchronizerService} from './multi-tab-synchronizer.service';
import {ShareableEntityService} from './shareableEntity.service';

export interface RouterOptions {
  formId?: string;
  angularOnly?: boolean;
  skipLeaveMessage?: boolean;
  skipLocationChange?: boolean;
  replaceUrl?: boolean;
}

@Injectable()
export class RedirectorService {
  public static LIST_CLASSES = ['LayoutList', 'LayoutTree', 'LayoutDataView', 'LayoutKanban'];
  public static FORM_CLASSES = ['LayoutGraph', 'LayoutWebsite', 'LayoutForm'];

  public constructor(private modelService: ModelService, private dynamicContentService: DynamicContentService,
                     private circularService: CircularService, protected layoutConditionService: LayoutConditionService,
                     private messageService: MessageService, private translateService: TranslateService,
                     private router: Router, private multiTab: MultiTabSynchronizerService,
                     private shareableEntityService: ShareableEntityService) {
  }

  /**
   * redirects to an entity by evaluating layout conditions and applying ctrl click rules
   */
  public async redirectToEntity(entity: KolibriEntity, event: MouseEvent | TouchEvent | KeyboardEvent = {} as any, queryString: string = '',
                                layoutList?: ListLayout, options: RouterOptions & Partial<DynamicContent> = {}): Promise<boolean> {
    return this.redirect(await this.getEntityUrl(entity, layoutList, options.formId, {
      clickEvent: event
    }) + queryString, event, entity, layoutList, options);
  }

  /**
   * open a specific layout
   */
  public openLayout(layoutName: string, event: MouseEvent | TouchEvent | KeyboardEvent = {} as any,
                    options?: RouterOptions & Partial<DynamicContent>): Promise<boolean> {
    const layout = this.modelService.getLayout(layoutName);
    const app = this.modelService.getApplication(layout.applicationId);
    const entity = this.modelService.getEntity(layout.entityId);
    const viewLayout = layout ? `/${[app.name, entity.name, layout.url].join('/')}` : 'error/403';

    return this.redirect(viewLayout, event, undefined, undefined, options);
  }

  /**
   * force open a record in a specific layout
   */
  public openRecordInLayout(record: KolibriEntity, layoutName: string, event: MouseEvent | TouchEvent | KeyboardEvent = {} as any,
                            options?: RouterOptions & Partial<DynamicContent>): Promise<boolean> {
    const layout = this.modelService.getLayout(layoutName);
    const app = this.modelService.getApplication(layout.applicationId);
    const viewLayout = layout ? `/${[app.name, record.entityClass, layout.url].join('/')}?${_.decapitalize(
      record.entityClass)}Id=${record.id}` : 'error/403';

    return this.redirect(viewLayout, event, record, undefined, options);
  }

  /**
   * get entity layout name by evaluating layout conditions
   */
  public async getEntityLayout(entity: KolibriEntity, layoutList?: ListLayout, formId?: string): Promise<string> {
    return (await this.layoutConditionService.getViewLayoutFromRecord(entity, layoutList, formId)).viewLayout.name;
  }

  /**
   * get entity url by evaluating layout conditions
   */
  public getEntityUrl(entity: KolibriEntity, layoutList?: ListLayout, formId?: string, params?: ScriptParams): Promise<string> {
    if (entity.persisted) {
      return this.layoutConditionService.getViewUrlFromRecord(entity, layoutList, formId, params);
    } else {
      const entityMeta = this.modelService.getEntity(entity.entityClass);
      return this.layoutConditionService.getCreateUrlFromRecord(entityMeta) as any;
    }
  }

  /**
   * close the entity tab in page right
   */
  public closePageRight(entity: KolibriEntity): void {
    this.dynamicContentService.remove((entity.id || entity.representativeString) + entity.entityClass);
  }

  /**
   * Redirects to the url if there is no special case like ctrl and alt is pressed.
   * If layoutList is given, and it has ForceDetailRecordView it redirect to the DetailUrl.
   * @param url redirect to this url if no special case
   * @param event the event that was triggered
   * @param entity the data for scripts and layouts (optional)
   * @param layoutList the layout list (optional)
   * @param angularRouting using angular routing or not
   */
  public async redirect(url: string, event: MouseEvent | TouchEvent | KeyboardEvent, entity?: KolibriEntity, layoutList?: ListLayout,
                        {angularOnly = true, formId, ...options}: RouterOptions & Partial<DynamicContent> = {}): Promise<boolean> {
    const ctrlKey = UiUtil.hasKeyPressedForOs(event, 'Control');
    const shiftKey = UiUtil.hasKeyPressedForOs(event, 'Shift');
    const altKey = UiUtil.hasKeyPressedForOs(event, 'Alt');

    if (ctrlKey) {
      if (altKey && entity) {
        const entityMeta = this.modelService.getEntity(entity.entityClass);
        const viewLayout = await this.circularService.layoutConditionService.getViewLayout(entity, entityMeta, layoutList, formId);
        if (!viewLayout) {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: this.translateService.instant('Error.Forbidden')
          });
          return false;
        }
        this.dynamicContentService.add(DynamicContentType.PAGE_RIGHT, (entity.id || entity.representativeString) + entity.entityClass, viewLayout.name,
          {data: entity, icon: entityMeta.icon, background: shiftKey, label: entity.representativeString || entity.id, ...options});
      } else {
        window.open(url, '_blank')?.blur();
        window.focus();
      }
    } else {
      if (angularOnly) {
        return this.router.navigateByUrl(url, options);
      } else {
        window.location.href = url;
      }
    }
  }

  public async doDrillDown(id: string, drillDownName: string, entityMeta: Entity, query: CriteriaQueryJson, applicationName: string,
                           event: MouseEvent | TouchEvent, layoutUrl?: string, asHiddenPayload: boolean = false): Promise<boolean> {
    const emptyPayload = (await this.shareableEntityService.getEmptyPrefQuery(entityMeta, entityMeta.id)).payload;

    const payload = asHiddenPayload ? emptyPayload : query;
    const hiddenPayload = asHiddenPayload ? query : undefined;

    const drillDownQuery: Partial<PrefQuery> = {
      id: hash(this.circularService.currentUser.id + id),
      name: {[this.translateService.currentLang]: drillDownName},
      scope: entityMeta.id,
      payload,
      hiddenPayload,
      entityClass: 'PrefQuery',
      representativeString: drillDownName,
      visibility: await this.shareableEntityService.getFirstAllowedShareVisibility()
    };

    // submit to user session and redirect to default layout
    this.multiTab.fire(`drilldownQuery${drillDownQuery.id}`, drillDownQuery);
    // if drilldown comes from relatedList take the specified List otherwise use default
    let url: string;
    if (layoutUrl) {
      url = layoutUrl;
    } else if (entityMeta.defaultListId) {
      url = this.modelService.getLayout(entityMeta.defaultListId).url;
    } else {
      url = _.find(entityMeta.layouts,
        layout => RedirectorService.LIST_CLASSES.includes(layout.entityClass) && !!layout.url).url;
    }

    return this.redirect(`/${applicationName}/${_.decapitalize(entityMeta.name)}/${url}?drilldown=${drillDownQuery.id}`, event);
  }
}
