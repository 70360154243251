import * as moment from 'moment';
import {DesignTheme} from '../xml/design-theme';
import {Attachment} from './attachment';
import {Dashboard} from './dashboard';
import {EmailAccount} from './email-account';
import {KolibriEntity} from './kolibri-entity';

export enum EmailDisplayType {
  collapsed = 'collapsed',
  expanded = 'expanded'
}

export class PlatformSettings extends KolibriEntity {
  public companyDashboardId?: string;
  public companyDashboard?: Attachment;
  public companyLogoId?: string;
  public companyLogo?: Attachment;
  public loaderLogoId?: string;
  public loaderLogo?: Attachment;
  public defaultTheme?: DesignTheme;
  public defaultDashboard?: Dashboard;
  public defaultDashboardId?: string;
  public defaultEmailDisplayType?: EmailDisplayType;
  public defaultLanguage?: string;
  public loginInfoText?: string;
  public multiAuthErrorMessage?: string;
  public loginFooter?: string;
  public lastDatabaseSync?: string[];
  public activityStreamAccount?: EmailAccount;
  public userNotificationAccount?: EmailAccount;
  public userNotificationAccountId?: EmailAccount;
  public initWithIndexes?: boolean;
  public supportMail?: string;
  public modelUpgradeVersion?: string;
  public loginBackgroundImageId?: string;
  public loginBackgroundImage?: Attachment;
  public maintenanceStartDate?: string | moment.Moment;
  public maintenanceEndDate?: string | moment.Moment;
  public maintenanceMessage?: string;
}
