import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {_} from '@wspsoft/underscore';
import {MultiSelect as PrimengMultiSelect} from 'primeng/multiselect';
import {MultiSelect} from '../multi-select';

@Component({
  selector: 'ui-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['./multi-select-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectInputComponent extends MultiSelect<any> {
  @Input()
  public dataKey: string;
  @Input()
  public optionLabel: string = 'label';
  @Input()
  public optionValue: string = 'value';
  @Output()
  public onFilter: EventEmitter<{ filter: string; offset: number; cb: () => void }> = new EventEmitter();
  @ViewChild('nativeInput', {static: true})
  public nativeInput: PrimengMultiSelect;
  private overlayPanel: any;
  private scrollListenerBound: boolean = false;
  private checkScrollDebounced: () => void;
  private queryOffset: number = 0;
  private pFilter: string;

  public constructor() {
    super();

    this.checkScrollDebounced = _.debounce(this.checkScroll, 50);
  }

  private get filter(): string {
    return this.pFilter;
  }

  private set filter(newFilter: string) {
    // reset queryOffset if the filter changes, so we only fetch 30 records at the beginning
    if (newFilter !== this.filter) {
      this.queryOffset = 0;
    }

    this.pFilter = newFilter;
  }

  /**
   * must be there to refresh the input correctly. see more #2455
   */
  public doChange(): void {
    // does nothing, but has to be there because magic is happening
  }

  /**
   * emit event from primeng and enrich with cb for refresh
   */
  public emitOnFilter(filter: string, offset: number = 0): void {
    this.filter = filter;
    this.onFilter.emit({
      filter, offset, cb: () => {
        const nativeInput = this.nativeInput;
        nativeInput.activateFilter();
        nativeInput.cd.detectChanges();
      }
    });
  }

  protected bindScrollListener(): void {
    this.overlayPanel = this.nativeInput.overlayViewChild.contentViewChild.nativeElement.children[0].children[1];
    if (this.overlayPanel && !this.scrollListenerBound) {
      this.overlayPanel.addEventListener('scroll', this.checkScrollDebounced.bind(this));
      this.scrollListenerBound = true;
    }
  }

  protected removeScrollListenerAndReset(): void {
    this.overlayPanel?.removeEventListener('scroll', this.checkScrollDebounced.bind(this));
    this.queryOffset = 0;
    this.scrollListenerBound = false;
  }

  protected checkScroll(): void {
    // scrollTop = distance that has been scrolled
    // scrollHeight = the full scrollable height, including hidden content
    // offsetHeight = the height of the Overlay
    if (this.overlayPanel && (this.overlayPanel.scrollTop >= (this.overlayPanel.scrollHeight - this.overlayPanel.offsetHeight))) {
      this.queryOffset += 30;
      this.emitOnFilter(this.filter, this.queryOffset);
    }
  }
}
