<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'Choice'">
    <ui-choice-autocomplete [(ngModel)]="value" [choiceId]="typeName" [disable]="disable" [multiple]="multiple" [require]="require" [styleData]="styleData"
                            class="block-element" label="{{'FieldValueSelector.Value' | translate}}" name="choiceValue"></ui-choice-autocomplete>
  </ng-container>
  <ng-container *ngSwitchCase="'KolibriEntity'">
    <ui-entity-autocomplete [(ngModel)]="value" [convertToString]="!data" [converter]="showDeadValues ? entityDeadConverter : entityConverter"
                            [disable]="disable" [entityId]="entityName" [multiple]="multiple" [require]="require" [search]="search.bind(this)"
                            [styleData]="styleData" class="block-element" label="{{'FieldValueSelector.Value' |
                            translate}}" name="entityValue"></ui-entity-autocomplete>
  </ng-container>
  <ng-container *ngSwitchCase="'KolibriEntity[]'">
    <ui-multi-select-autocomplete [(ngModel)]="value" [convertToString]="!data" [disable]="disable" [dropdown]="true" [entityId]="entityName"
                                  [fieldId]="fieldId" [ignoreMappingEntity]="ignoreMappingEntity" [parentEntityId]="recordEntityClass" [require]="require"
                                  [search]="search.bind(this)" [styleData]="styleData" class="block-element" label="{{'FieldValueSelector.Value' | translate}}"
                                  name="entityValue"></ui-multi-select-autocomplete>
  </ng-container>
  <ng-container *ngSwitchCase="'Date'">
    <ui-calendar [(ngModel)]="value" [disable]="disable" [require]="require" [selectionMode]="multiple ? 'multiple' : 'single'" [showTime]="showTime"
                 [timeOnly]="timeOnly" label="{{'FieldValueSelector.Value' | translate}}" name="timeValue"></ui-calendar>
  </ng-container>
  <ng-container *ngSwitchCase="'DateRange'">
    <ui-date-range-selector [(ngModel)]="value" [disable]="disable" [require]="require" label="{{'FieldValueSelector.Value' | translate}}"
                            name="dateRangeValue"></ui-date-range-selector>
  </ng-container>
  <ng-container *ngSwitchCase="'Boolean'">
    <p-radioButton [(ngModel)]="value" [disabled]="disable" [value]="true" label="{{'RawData.true' | translate}}" name="{{id}}_radio"></p-radioButton>
    <p-radioButton [(ngModel)]="value" [disabled]="disable" [value]="false" label="{{'RawData.false' | translate}}" name="{{id}}_radio"></p-radioButton>
  </ng-container>
  <ng-container *ngSwitchCase="'LargeText'">
    <ui-large-textfield *ngIf="!multiple" [(ngModel)]="value" [disable]="disable" [require]="require" [styleData]="styleData"
                        label="{{'FieldValueSelector.Value' | translate}}" name="largeTextValue"></ui-large-textfield>
  </ng-container>
  <ng-container *ngSwitchCase="'MaybeBlob'">
    <ui-large-textfield *ngIf="!multiple" [(ngModel)]="value" [disable]="disable" [require]="require" [styleData]="styleData"
                        label="{{'FieldValueSelector.Value' | translate}}" name="maybeBlobValue"></ui-large-textfield>
  </ng-container>
  <ng-container *ngSwitchCase="'Number'">
    <ui-input-number [(ngModel)]="value" [currency]="symbol" [disable]="disable" [maxFractionDigits]="decimalPlaces" [minFractionDigits]="decimalPlaces"
                     [multiple]="multiple" [require]="require" [showSeparator]="transformEntity?.showSeparator ?? true" [step]="step" [styleData]="styleData"
                     label="{{'FieldValueSelector.Value' | translate}}" name="numberValue"></ui-input-number>
  </ng-container>
  <ng-container *ngSwitchCase="'Code'">
    <ui-large-textfield [(ngModel)]="value" [codeEditorOptions]="codeEditorOptions" [disable]="disable" [enableMinimap]="false" [language]="scriptLanguage"
                        [queryClass]="queryEntityClass" [recordClass]="recordEntityClass" [require]="require" [rows]="1" [styleData]="styleData"
                        editorMode="CODE_EDITOR" label="{{'FieldValueSelector.Value' | translate}}" name="codeValue"></ui-large-textfield>
  </ng-container>
  <ng-container *ngSwitchCase="'I18n'">
    <ui-input-text [(ngModel)]="value" [disable]="disable" [isI18n]="!ignoreI18nInputs" [multiple]="multiple" [require]="require" [styleData]="styleData"
                   label="{{'FieldValueSelector.Value' | translate}}" name="stringValue"></ui-input-text>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ui-input-text [(ngModel)]="value" [disable]="disable" [multiple]="multiple" [require]="require" [styleData]="styleData"
                   label="{{'FieldValueSelector.Value' | translate}}" name="stringValue"></ui-input-text>
  </ng-container>
</ng-container>
