import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ACLOperations,
  AdditionalRenderData,
  BundleKeyGenerator,
  Field,
  KolibriEntity,
  Relation,
  RenderType,
  Utility
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {CircularService, ModelService, ModelTranslationService, TypeService} from '../../../../../api';
import {UiUtil} from '../../../util/ui-util';
import {DisplayTransformationConverterService} from '../../converter/display-transformation-converter.service';

@Component({
  selector: 'ui-field-translator',
  templateUrl: './field-translator.component.html',
  styleUrls: ['./field-translator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: DisplayTransformationConverterService
  }]
})
export class FieldTranslatorComponent implements OnInit, OnChanges {
  public BundleKeyGenerator: typeof BundleKeyGenerator = BundleKeyGenerator;
  public hideRepImage: boolean;
  @Input()
  public repImageDimension: string = '40';
  @Input()
  public typeName: string;
  @Input()
  public data: any;
  @Input()
  public meta: Field;
  @Input()
  public field: string;
  @Input()
  public converter?: (obj: any) => any;
  @Input()
  public renderType?: RenderType;
  @Input()
  public transformScript?: any;
  @Input()
  public transformId?: string;
  @Input()
  public showIcon?: boolean = true;
  @Input()
  public showText?: boolean = true;
  @Input()
  public translateNull?: boolean;
  @Input()
  public rounded?: boolean = true;
  @Input()
  public showEmptyAsValue: boolean = false;
  @Input()
  public showDeadValue: boolean = false;
  public transformedStyle: { [p: string]: any };
  public transformedColor: string;
  public transformedIcon: string;
  public RenderType = RenderType;
  @HostBinding('class.one-helper--flexwrap')
  public multiple: boolean;
  public translatedValue: string | string[];

  public constructor(private modelTranslationService: ModelTranslationService, private modelService: ModelService,
                     private translate: TranslateService,
                     private cdr: ChangeDetectorRef, public typeService: TypeService, public circularService: CircularService,
                     public converterService: DisplayTransformationConverterService) {
  }

  /**
   * returns the entityValue
   *
   * Note:
   * return value is an entity and maybe a user with initials.
   * Initials is used in html and will be linting with error,
   * because initials is not an attribute on KolibriEntity
   */
  public get entityValue(): KolibriEntity & { initials?: string } {
    const loadData = Utility.isRelation(this.meta) || Utility.isDotWalk(this.field);
    if (loadData) {
      const maybeProm = this.data[Utility.wordifyDotWalk(this.field,
        Utility.isDotWalk(this.field))];
      return _.isPromise(maybeProm) ? maybeProm.then(() => this.cdr.detectChanges()) : maybeProm;
    } else {
      return this.data;
    }
  }

  public get convertValue(): any {
    if (!this.showText) {
      return '';
    }
    if (this.translatedValue === undefined) {
      let additionalRenderData: AdditionalRenderData;
      if (this.converterService.displayTransform && !_.isNull(this.converterService.displayTransform.showSeparator)) {
        additionalRenderData = {
          showSeparator: this.converterService.displayTransform.showSeparator
        };
      }

      _.maybeAwait(
        this.modelTranslationService.translateObjectValue(this.data, this.field, this.translate.currentLang, this.meta, this.translateNull, undefined,
          undefined, undefined, additionalRenderData),
        (x) => {
          if (x === '') {
            if (this.showDeadValue && Utility.isRelation(this.meta) && UiUtil.getIdValue(this.data, this.field)) {
              this.translatedValue = UiUtil.getIdValue(this.data, this.field);
            } else {
              this.translatedValue = this.emptyValue;
            }
          } else {
            this.translatedValue = _.isNull(x) ? this.emptyValue : this.converter ? this.converter(x) : x;
          }
          this.doTransform();
          this.multiple = Array.isArray(this.translatedValue);

          this.cdr.detectChanges();
        });
    }
    return this.translatedValue;
  }

  private get emptyValue(): string {
    return this.showEmptyAsValue ? this.translate.instant('Empty.Value') : '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // if the field data changes we need to reset the translatedValue in order for it to recalc the translation correctly
    if (changes.data && !changes.data.isFirstChange() && changes.data.previousValue[this.field] !== changes.data.currentValue[this.field]) {
      this.translatedValue = undefined;
    }
  }

  public ngOnInit(): void {
    if (!this.meta && this.data?.entityClass) {
      this.meta = this.getField();
    }

    if (this.meta) {
      // for relations, we have to check if we display the rep image, for attributes and dot-walks use entityId
      this.hideRepImage = this.modelService.getEntity(Utility.isRelation(this.meta) ? (this.meta as Relation).targetId : this.meta.entityId)?.hideRepImage;
    }

    if (!this.typeName) {
      if (this.data?.entityClass) {
        this.typeName = this.getFieldType();
      } else {
        this.typeName = 'string';
      }
    }

    let canReadField = true;
    if (this.data._securityInfo?.fieldInfo?.[this.field]?.at(ACLOperations.READ)) {
      canReadField = !!Number(this.data._securityInfo.fieldInfo?.[this.field]?.at(ACLOperations.READ));
    }
    if (this.data._securityInfo && !canReadField) {
      this.renderType = RenderType.SENSITIVE;
      return;
    }

    // executes the transformScript if a render type is present
    if (this.renderType) {
      if (this.renderType !== RenderType.STEPS && this.renderType !== RenderType.DISPLAYTRANSFORM) {
        _.maybeAwait(this.circularService.scriptExecutor.runScript<{ value: any; delayedValue: any }>(this.transformScript, {
          newValue: this.convertValue,
          record: this.data
        }, undefined, `FieldTranslation:${this.field}:TransformationEntity:transformScript`).result, (x) => {
          this.translatedValue = x?.value ?? this.convertValue;
          this.cdr.markForCheck();

          if (typeof x?.delayedValue === 'function') {
            _.maybeAwait(x.delayedValue(), (y) => {
              this.translatedValue = y?.value ?? this.convertValue;
              this.cdr.markForCheck();
            });
          }
        });
      }
    }

    this.converterService.displayTransform = this.modelService.getDisplayTransformation(this.transformId || this.meta?.displayTransformId);
  }

  public getField(): Field {
    return this.modelService.getField(this.data.entityClass, this.field);
  }

  public getFieldType(): string {
    return this.modelService.getTypeName(this.getField());
  }

  /**
   * Transforms the Value and Style of the List Column
   */
  public doTransform(): void {
    if (this.data.entityClass && this.field && this.renderType !== RenderType.STEPS && this.renderType !== RenderType.SENSITIVE) {
      if (this.converterService.hasNeedsConversion() && this.converterService.needsConversion({newValue: this.convertValue})) {
        this.translatedValue = this.converterService.getAsObject(this.convertValue as any);
      }
      _.maybeAwait(
        this.circularService.scriptExecutor.runScript<{ color?: string; icon?: string; style: { [p: string]: any } }>(
          this.transformScript ?? this.converterService.displayTransform?.transformScript, {
            newValue: this.convertValue,
            record: this.data
          }, undefined, `FieldTranslation:${this.field}:TransformationEntity:transformScript`).result, x => {
          this.transformedStyle = x?.style;
          this.transformedIcon = x?.icon;
          this.transformedColor = x?.color;
        });
    }
  }

  public isNotEmpty(translation: any): boolean {
    return !!translation?.length || !_.isNullOrEmpty(translation);
  }
}
