import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {KolibriEntity} from '@wspsoft/frontend-backend-common';
import {SelectItem, SelectItemGroup} from 'primeng/api';
import {Dropdown} from 'primeng/dropdown';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {EntityServiceFactory, ModelService} from '../../../../../api';
import {UiUtil} from '../../../util/ui-util';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent extends CustomInput<any> implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public options: (KolibriEntity | SelectItem | SelectItemGroup)[];
  @Input()
  public dataKey: string = 'label';
  @Input()
  public dropdownIcon: string = 'fas fa-fw fa-chevron-down';
  @Input()
  public overlayStyleClass: string;
  @Input()
  public panelStyleClass: string;
  @Input()
  public placeholder: string;
  @Input()
  public styleClass: string;
  @Input()
  public lazy: boolean;
  @Input()
  public minCharacters: number;
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public autoDisplayFirst: boolean;
  @Input()
  public filter: boolean;
  @Output()
  public onShow: EventEmitter<any> = new EventEmitter();
  @Output()
  public onHide: EventEmitter<any> = new EventEmitter();
  @Output()
  public onComplete: EventEmitter<string> = new EventEmitter();
  @ViewChild(Dropdown)
  public dropdown: Dropdown;
  @Input()
  public entityId: string;
  @Input()
  public groupBy: string;
  @ContentChild('itemTemplate', {static: true})
  public itemTemplate: TemplateRef<any>;
  @ContentChild('filterTemplate', {static: true})
  public filterTemplate: TemplateRef<any>;
  @Input()
  public optionLabel: string;
  public emptyMessage: string;
  private searchChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;

  public constructor(private modelService: ModelService, private entityServiceFactory: EntityServiceFactory) {
    super();
    this.subscription = this.searchChanged
      .pipe(
        debounceTime(500))
      .subscribe($event => {
        this.setEmptyMessage($event);
        if (this.minCharacters && $event?.length < this.minCharacters) {
          this.options = [];
        } else {
          this.dropdown.filterValue = $event;
          this.onComplete.emit($event);
        }
        this.cdr.detectChanges();
      });
  }

  public async ngOnInit(): Promise<void> {
    this.setEmptyMessage();
    if (this.groupBy && this.options.length) {
      // the dropdown might render before the async call is ready and then render invalid groups
      const options = this.options;
      this.options = [];
      this.options = await UiUtil.groupEntitiesIntoSelectItemGroups(options, this.groupBy, this.modelService,
        this.entityServiceFactory.getService(this.entityId));
      this.cdr.detectChanges();
    }
  }

  public ngAfterViewInit(): void {
    // mainly copy paste from dropdown component but with lazy hijack
    const activateFilter = this.dropdown.activateFilter.bind(this.dropdown);
    this.dropdown.activateFilter = () => {
      if (!this.lazy) {
        activateFilter();
      }
    };
    const onFilter = this.dropdown.onFilterInputChange.bind(this.dropdown);
    this.dropdown.onFilterInputChange = (event: KeyboardEvent) => {
      // @ts-ignore
      const inputValue = event.target.value;
      if (this.lazy) {
        // this is part of the original function
        // might fix bugs?
        if (inputValue && inputValue.length) {
          this.dropdown._filterValue = inputValue;
        } else {
          this.dropdown._filterValue = null;
        }

        return this.searchChanged.next(inputValue);
      }
      onFilter(event);
    };

    this.dropdown.onItemClick = function onItemClick(event: any): void {
      const option = event.option;

      if (option.command) {
        option.command(event, this);
      } else if (!option.disabled) {
        this.selectItem(event, option);
        this.accessibleViewChild.nativeElement.focus();

        setTimeout(() => {
          this.hide();
        }, 150);
      }
    };
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public show(): void {
    this.dropdown.show();
    this.dropdown.cd.detectChanges();
  }

  public hide(): void {
    this.dropdown.hide();
    this.dropdown.cd.detectChanges();
  }

  /**
   * set empty message depending on search string
   */
  private setEmptyMessage(query?: string): void {
    this.emptyMessage = this.minCharacters && (!query || query.length < this.minCharacters) ? 'AutoComplete.MinCharSearch' : 'AutoComplete.NoResults';
  }
}
