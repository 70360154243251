import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {ControlContainer, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Attachment, AttachmentUtil, EntityModel, KolibriEntity, RelationInfo, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {Message, MessageService} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import {Subscription} from 'rxjs';
import * as uuidv4 from 'uuid-random';
import {EntityService, EntityServiceFactory, FileService, ModelService} from '../../../../../api';
import {environment} from '../../../../../config/environments/environment';

import {KolibriEntityConverterService} from '../../converter/kolibri-entity-converter.service';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true,
  }, {
    provide: KolibriEntityConverterService
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent extends CustomInput<Attachment | Attachment[]> implements OnInit, OnDestroy {
  public environment: typeof environment = environment;
  public staticPath: string = (window as any).staticPath;
  @Input()
  public renderInputGroup: boolean = false;
  @Input()
  public fileType: string;
  @Input()
  public tooltip: string = 'FileUpload.Label';
  @Input()
  public dropContainer: ElementRef<HTMLElement>;
  @Input()
  public parentEntityId: string;
  @Input()
  public publik: boolean;
  @Input()
  public imageCropper: boolean;
  @Input()
  public delayedSave: boolean;
  @Input()
  public temporary: boolean = false;
  @Input()
  public chooseIcon: string = 'pi pi-plus';
  @Input()
  public maxImageWidth: number;
  @Input()
  public maxImageHeight: number;
  @Input()
  public maxSize: number;
  @Input()
  public formId: string;
  /**
   * only for multiple implemented yet
   * @type {boolean}
   */
  @Input()
  public hideOutput: boolean = false;
  @Input()
  public relationInfo: RelationInfo;
  @Input()
  public targetEntityMeta: EntityModel;
  @Output()
  public upload: EventEmitter<Attachment | Attachment[]> = new EventEmitter();
  @Output()
  public remove: EventEmitter<Attachment | Attachment[]> = new EventEmitter();
  @Output()
  public delayedAction: EventEmitter<(record: KolibriEntity) => Promise<any>> = new EventEmitter();
  public showCropper: boolean = false;
  public originalFile: File;
  public croppedFile: File;
  protected service: EntityService<Attachment>;
  @ViewChild(FileUpload, {static: false})
  private fileUpload: FileUpload;
  private subscription: Subscription;
  private isMappingEntity: boolean = false;
  private mappingEntity: EntityModel;
  private mappingService: EntityService<KolibriEntity>;

  public constructor(entityConverter: KolibriEntityConverterService, private element: ElementRef,
                     public translate: TranslateService, private messageService: MessageService,
                     serviceFactory: EntityServiceFactory, private modelService: ModelService, private fileService: FileService,
                     private entityServiceFactory: EntityServiceFactory) {
    super();
    entityConverter.entityNameOrId = 'Attachment';
    this.converter = entityConverter;
    this.service = serviceFactory.getService('Attachment');
  }

  public setValue(value: Attachment | Attachment[], emitChange: boolean = true): void {
    if (this.isMappingEntity) {
      this.fillFileNameToMapping(value as Attachment[]).then(() => {
        super.setValue(value, emitChange);
        this.cdr.detectChanges();
      });
    } else {
      super.setValue(value, emitChange);
    }
  }

  public ngOnInit(): void {
    this.dropContainer ??= this.element;
    if (!this.multiple) {
      // grab the incoming file and fetch payload for image crapper
      this.subscription = this.convertChange.subscribe(async () => {
        if (this.value && this.imageCropper) {
          await this.setFile();
        }
      });
    }

    if (this.targetEntityMeta?.mappingEntity) {
      this.isMappingEntity = true;
      const {destinationRelation} = this.relationInfo;
      this.mappingEntity = this.targetEntityMeta;
      this.mappingService = this.entityServiceFactory.getService(this.mappingEntity.name);
      this.targetEntityMeta = this.modelService.getEntityByType(destinationRelation);
    }

    if (this.dropContainer) {
      this.initDropZone();
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async uploadFile($event: { files: File[] }): Promise<void> {
    let toSave: Attachment[] = [];
    const attachment = await this.service.getNewEntity(this.formId);
    try {
      for (const file of $event.files) {
        if (file.size === 0) {
          this.messageService.add({
            detail: this.translate.instant('FileUpload.Empty'),
            summary: '',
            severity: 'warn'
          });
          if (!this.multiple) {
            return;
          } else {
            continue;
          }
        }

        toSave.push(await this.fileToAttachment(attachment.copy(), file));
      }
      const saveFn = async (record: KolibriEntity): Promise<void> => {
        for (const toSaveElement of toSave) {
          if (this.isMappingEntity === false) {
            toSaveElement[this.relationInfo?.parentRelation.targetRelationName ?? 'entity'] = record;
          } else {
            const destinationRelationName = this.relationInfo.destinationRelation.name;
            // if mapping entity add the record to the source relation
            toSaveElement[this.relationInfo.sourceRelation.name] = record;
            // if mapping save the attachment
            toSaveElement[destinationRelationName] = await this.service.updateEntity(toSaveElement[destinationRelationName]);
          }
        }
        if (this.multiple) {
          if (this.isMappingEntity) {
            toSave = await this.mappingService.updateEntity(toSave);
          } else {
            // users might be crazy and upload gigantic amount of data :(
            toSave = await this.service.updateEntity(toSave);
          }

          if (this.delayedSave) {
            const newIds = toSave.map(value => value.id);
            // replace old not persisted toSave records and add the new persisted ones
            const filteredValue = ((this.value as Attachment[]) ?? []).filter(value => !newIds.find(id => id === value.id));
            this.value = [...filteredValue, ...toSave];
          }
        } else {
          // save persisted version in toSave
          toSave[0] = await toSave[0].update();

          if (this.delayedSave) {
            this.value = toSave[0];
            await this.setFile();
          }
        }
      };

      // i.e. when adding an attachment for an email, which shouldn't be saved to db
      if (!this.temporary) {
        if (this.delayedSave) {
          this.delayedAction.emit(saveFn);
        } else {
          await saveFn({id: this.parentEntityId});
        }
      }

      if (!this.multiple) {
        if (this.value) {
          const value = (this.value as Attachment);
          const deleteFn = (): Promise<void> => this.service.deleteEntity(value.id, undefined, true);
          if (this.delayedSave) {
            this.delayedAction.emit(deleteFn);
          } else {
            await deleteFn();
          }
        }

        this.value = toSave[0];
        await this.setFile();
      } else if (this.multiple && !this.hideOutput) {
        // don't delete old and don't replace like in single mode just append
        this.value = [...((this.value as Attachment[]) ?? []), ...toSave];
      }
      this.upload.emit(this.value || toSave);
    } finally {
      this.clear();
      this.cdr.detectChanges();
    }
  }

  public onRemove(attachment: Attachment): void {
    this.remove.emit(attachment);
  }

  public clear(): void {
    this.fileUpload.clear();
  }

  public cropFile(): Promise<void> {
    return this.uploadFile({files: [this.croppedFile]});
  }

  public deleteFile(cb: () => void): void {
    let deleteFn = null;
    if (!this.multiple) {
      const idToDelete = (this.value as Attachment).id;
      deleteFn = async (): Promise<void> => {
        await this.service.deleteEntity(idToDelete);
      };
      this.value = null;
      this.originalFile = null;
    } else if (this.multiple && !this.hideOutput) {
      const idsToDelete = (this.value as Attachment[]).map(a => a.id);
      deleteFn = async (): Promise<void> => {
        if (this.isMappingEntity) {
          await this.mappingService.deleteEntities(idsToDelete);
        } else {
          await this.service.deleteEntities(idsToDelete);
        }
      };
      // don't hide non persisted values because they are in delayed save and will be generated anyway
      const filteredValues = (this.value as Attachment[] ?? []).filter(value => !value.persisted);
      if (filteredValues.length) {
        this.value = filteredValues;
      } else {
        this.value = null;
      }
      this.originalFile = null;
    }
    this.delayedAction.emit(deleteFn);
    // trigger file upload change notification
    this.upload.emit(this.value);
    cb();
  }

  public deleteOneFile(att: Attachment): void {
    const idToDelete = att.id;
    const deleteFn = async (): Promise<void> => {
      if (this.isMappingEntity) {
        await this.mappingService.deleteEntity(idToDelete);
      } else {
        await this.service.deleteEntity(idToDelete);
      }
    };
    this.delayedAction.emit(deleteFn);
    if (this.multiple && !this.hideOutput) {
      const filteredValues = (this.value as Attachment[]).filter(a => a.id !== idToDelete);
      if (filteredValues.length) {
        this.value = filteredValues;
      } else {
        this.value = null;
      }
    }
  }

  public validate(): ValidationErrors {
    if (!this.multiple && !this.value && this.require && !this.disable) {
      return {
        required: true
      };
    } else if (this.multiple && _.isNullOrEmpty(this.value) && this.require && !this.disable) {
      return {
        required: true
      };
    }
  }

  /**
   * catch all messages from primeng and show them as growl
   */
  public showMessage(msgs: Message[]): void {
    for (const msg of msgs) {
      this.messageService.add({
        ...msg,
        key: 'growl',
        detail: msg.summary,
        summary: ''
      });
    }
  }

  public maxFileSize(): number {
    // @ts-ignore
    return (Math.min(this.maxSize || 60000, window.attachmentMaxSize)) * 1000;
  }

  public checkForSvg(): void {
    if (this.originalFile?.type === 'image/svg+xml') {
      this.messageService.add({
        detail: this.translate.instant('FileUpload.Svg'),
        summary: '',
        severity: 'warn'
      });

      this.showCropper = false;
    }
  }

  public showDropzone(): boolean {
    return !this.disable && this.renderInputGroup && !this.value;
  }

  public getAttachmentId(val: Attachment): string {
    if (this.isMappingEntity && this.mappingEntity.name === val.entityClass) {
      return val[Utility.parameterizeEntityName(this.relationInfo.destinationRelation.name)];
    }
    return val.id;
  }

  private getTypeByMagicNumber(file: File): Promise<string> {
    const reader = new FileReader();
    reader.readAsText(file.slice(0, 16));
    return new Promise((resolve, reject) => {
      reader.onloadend = event => {
        try {
          if (event.target.readyState === FileReader.DONE) {
            const result = event.target.result as string;
            switch (true) {
              case result.includes('ftypheic'):
                return resolve('image/heic');
              default:
                return resolve('unknown');
            }
          }
        } catch (e: any) {
          return reject(e);
        }
      };
    });
  }

  private async fileToAttachment(attachment: Attachment, file: File): Promise<Attachment> {
    await AttachmentUtil.fileToAttachment(attachment, file);

    const type = file.type || await this.getTypeByMagicNumber(file);

    if (type.startsWith('image/')) {
      const supportedMimeTypes = ['jpeg', 'png', 'webp', 'avif', 'tiff', 'gif', 'svg'];
      if (!supportedMimeTypes.some(mimeType => type.includes(mimeType))) {
        attachment.payload = await this.fileService.convert(attachment.payload, {sourceFormat: type, targetFormat: 'png'});
        attachment.mimeType = 'image/png';
        attachment.fileName = attachment.fileName.replace(attachment.fileName.split('.').pop(), 'png');
      }

      attachment.payload = await this.fileService.resize(attachment.payload,
        {height: this.maxImageHeight, width: this.maxImageWidth, fit: 'inside', withoutEnlargement: true});
    }

    attachment.id = uuidv4();
    attachment.publik = this.publik;

    // if the relation is m2m then create and return a mapping record
    if (this.isMappingEntity) {
      const mapping = await this.mappingService.getNewEntity();
      mapping.id = uuidv4();
      mapping[this.relationInfo.destinationRelation.name] = attachment;
      return mapping;
    }
    return attachment;
  }

  private setFile(): Promise<void | void[]> {
    if (this.multiple && !this.hideOutput) {
      return Promise.all((this.value as Attachment[]).map(value => this.setAttachment(value)));
    } else if (!this.multiple) {
      return this.setAttachment(this.value as Attachment);
    }
  }

  private async setAttachment(attachment: Attachment): Promise<void> {
    // prefer local data over s3 stuff
    const res = attachment.payload ? await fetch(`data:${attachment.mimeType};base64,${attachment.payload}`) : await fetch(
      `${environment.serverAdd}/files/${attachment.id}`);
    const blob = await res.blob();
    this.originalFile = new File([blob], attachment.fileName, {type: attachment.mimeType});
    this.cdr.detectChanges();
  }

  private initDropZone(): void {
    const nativeElement = this.dropContainer.nativeElement;
    nativeElement.ondrop = e => {
      nativeElement.classList.remove('one-helper--highlight');
      this.fileUpload.onDrop(e);
      // Prevent default behavior (Prevent file from being opened)
      e.preventDefault();
    };

    nativeElement.ondragover = e => {
      nativeElement.classList.add('one-helper--highlight');
      // Prevent default behavior (Prevent file from being opened)
      e.preventDefault();
    };
    nativeElement.ondragleave = () => {
      nativeElement.classList.remove('one-helper--highlight');
    };
  }

  private fillFileNameToMapping(value: Attachment[]): Promise<void> {
    if (value && value.length) {
      return _.parallelDo(value, async (v) => {
        if (!v.fileName) {
          v.fileName = (await v[this.relationInfo.destinationRelation.name])?.fileName;
        }
      });
    }
    return Promise.resolve();
  }
}
