import {AfterContentInit, Component, NgZone} from '@angular/core';
import {UserService} from '../../../../api';
import {FormComponent} from '../../../../ui';

@Component({
  selector: 'login-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss', '../form.scss']
})
export class RequestPasswordResetComponent extends FormComponent implements AfterContentInit {
  public username: string;
  public passwordChangeRequested: boolean = false;
  public success: boolean;

  public constructor(private zone: NgZone, private userService: UserService) {
    super();

    // prevent basic success message from formComponent to show when submitting the reset form
    this.showSuccessMessage = false;
  }

  public ngAfterContentInit(): void {
    // well there is no real done, so trigger some time later...
    const subscription = this.zone.onStable.subscribe(() => {
      subscription.unsubscribe();
      this.focusFirstEmptyInput();
    });
  }

  public async doSave(): Promise<boolean> {
    this.passwordChangeRequested = true;

    try {
      const success = await this.userService.requestPasswordReset(this.username);
      if (!success) {
        throw Error('sending email failed');
      }

      // inform the user that the password reset will continue in the email
      this.messageService.add({
        severity: 'success',
        detail: this.translateService.instant('Login.PasswordReset.Success'),
        summary: '',
        key: 'growl'
      });
      return true;
    } catch (e: any) {
      // inform the user that the password reset failed
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('Login.PasswordReset.Error'),
        summary: '',
        key: 'growl'
      });
      return false;
    }
  }

  public reset(cb: () => void): void {
    window.location.reload();

    cb();
  }

  public redirectToLogin(): void {
    // @ts-ignore, because typing is wrong
    window.location = '/auth/login';
  }
}
