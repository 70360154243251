import {AfterContentChecked, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList} from '@angular/core';
import {DialogComponent} from '../dialog/dialog.component';

import {WizardPageComponent} from '../wizard-page/wizard-page.component';

@Component({
  selector: 'ui-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardComponent<T> extends DialogComponent<T> implements AfterContentChecked {
  @Input()
  public nextButton: boolean;
  @Input()
  public startPage: string;
  @Input()
  public lastPage: (page: WizardPageComponent) => boolean;
  @Input()
  public activeIndex: number = 0;
  public indexHistory: number[] = [];
  public activePage: WizardPageComponent;
  @ContentChildren(WizardPageComponent)
  private pages: QueryList<WizardPageComponent>;

  public constructor() {
    super();
  }

  public get isFirstPage(): boolean {
    return this.activeIndex === 0;
  }

  public get isLastPage(): boolean {
    return this.activeIndex === this.pages.length - 1;
  }

  public ngAfterContentChecked(): void {
    // set active page to 0 if none is set
    if (!this.activePage) {
      this.pages.forEach((item, index) => item.index = index);

      if (this.startPage) {
        this.jumpTo(this.startPage);
      } else {
        this.jumpToIndex(0);
      }
    }
  }

  public back(): void {
    if (this.isFirstPage) {
      return;
    }
    // goto last page saved in history (-- might not be correct when using jump methods)
    this.jumpToIndex(this.indexHistory.pop());
  }

  public next(): void {
    if (this.isLastPage) {
      return;
    }
    // remember current page for back
    this.indexHistory.push(this.activeIndex);
    // simply count up for next page
    this.jumpToIndex(this.activeIndex + 1);
  }

  public jumpToIndex(i: number): void {
    this.activePage = this.pages.toArray()[i];
    this.activeIndex = i;
    this.cdr.detectChanges();
  }

  public jumpTo(name: string): void {
    this.jumpToIndex(this.pages.find(item => item.name === name).index);
  }
}
