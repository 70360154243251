import {ChangeDetectionStrategy, Component, forwardRef, Host, Input, OnChanges, Optional, SimpleChanges, SkipSelf} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChoiceOptionDisplayType, DisplayButtonType} from '@wspsoft/frontend-backend-common';
import {SelectItem} from 'primeng/api';
import * as uuidv4 from 'uuid-random';
import {ButtonSize} from '../../../entities/button-entities';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectButtonComponent),
    multi: true
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectButtonComponent extends CustomInput<number | number[]> implements OnChanges {
  public ChoiceOptionDisplayType: typeof ChoiceOptionDisplayType = ChoiceOptionDisplayType;
  @Input()
  public options: SelectItem[];
  @Input()
  public type: DisplayButtonType = DisplayButtonType.FORM;
  @Input()
  public size: ButtonSize = ButtonSize.SMALL;
  @Input()
  public displayType: ChoiceOptionDisplayType = ChoiceOptionDisplayType.SWITCH;
  @Input()
  public dataKey: string;
  @Input()
  public orientation: string = 'horizontal';
  @Input()
  public clearable: boolean = true;
  public id: string = uuidv4();

  public constructor(@Optional() @Host() @SkipSelf()
                     public container: ControlContainer) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options || changes.required) {
      this.doAutoSelect();
    }
  }

  private doAutoSelect(): void {
    if (this.require && this.options?.length === 1) {
      this.value = this.multiple ? [this.options[0].value] : this.options[0].value;
    }
  }
}

