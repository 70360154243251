<ngx-ui-loader *ngIf="!logoLoader && !progressBar" [gap]="8" [hasProgressBar]="false" [loaderId]="loaderId" [logoSize]="200" fgsColor="rgba(255,255,255,0)"
               logoPosition="center-center" logoUrl="{{environment.serverAdd}}{{staticPath}}/images/digits.gif" overlayColor="rgba(255,255,255,0.7)"
               textColor="rgba(255,255,255,0)"></ngx-ui-loader>

<ngx-ui-loader *ngIf="logoLoader && !progressBar" [gap]="8" [hasProgressBar]="false" [loaderId]="loaderId" [logoSize]="200" fgsColor="rgba(255,255,255,0)"
               logoPosition="center-center" logoUrl="{{environment.serverAdd}}/files/{{logo}}" overlayColor="rgba(255,255,255,0.7)"
               textColor="rgba(255,255,255,0)"></ngx-ui-loader>

<ngx-ui-loader *ngIf="progressBar" [gap]="8" [hasProgressBar]="true" [loaderId]="loaderId" [pbColor]="progressBarColor" [pbThickness]="5" class="progress-bar"
               fgsColor="rgba(255,255,255,0)" overlayColor="rgba(255,255,255,0)"></ngx-ui-loader>
