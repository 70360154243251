<p-galleria *ngIf="!editEnabled" [(activeIndex)]="activeIndex" [circular]="true" [numVisible]="numVisible" [showItemNavigators]="false"
            [showThumbnails]="hasImages" [value]="convertedImages" class="one-gallery {{!hasImages ? 'one-gallery--empty' : ''}}">
  <ng-template pTemplate="header">
    <ng-container *ngIf="header" [ngTemplateOutlet]="header"></ng-container>
  </ng-template>
  <ng-template let-image pTemplate="item">
    <div class="one-gallery__item">
      <div class="one-gallery__item-content">
        <div class="one-gallery__item-image-container">
          <ng-container *ngIf="hasImages && image else placeholder">
            <a class="one-gallery__item-link one-gallery__item-link--{{aspectRatio}}" href="{{image?.url}}" target="_blank">
              <div class="one-gallery__item-link-hover grid align-items-center grid-nogutter justify-content-center">
                <i class="one-gallery__item-link-hover__icon fas fa-fw fa-search"></i>
              </div>
              <img *ngIf="!editEnabled" [alt]="image.attachment.fileName" [title]="image.attachment.fileName" class="one-gallery__item-image"
                   defaultImage="{{environment.serverAdd}}{{staticPath}}/images/digits.gif"
                   lazyLoad="{{image.url}}?{{aspectRatioDimension}}&_rev={{image.attachment._rev}}"/>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-image pTemplate="thumbnail">
    <ng-container *ngIf="image">
      <img [alt]="image.attachment.fileName" class="one-gallery__thumbnail" defaultImage="{{environment.serverAdd}}{{staticPath}}/images/digits.gif"
           lazyLoad="{{image.url}}?width=50&height=50&_rev={{image.attachment._rev}}"/>
      <ng-container *ngIf="thumbnail" [ngTemplateOutletContext]="{ $implicit: image }" [ngTemplateOutlet]="thumbnail"></ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="previousthumbnailicon">
    <span class="fa fa-chevron-left"></span>
  </ng-template>
  <ng-template pTemplate="nextthumbnailicon">
    <span class="fa fa-chevron-right"></span>
  </ng-template>
</p-galleria>

<ng-template #placeholder>
  <svg aria-hidden="true" class="one-gallery__item-image" data-icon="images" data-prefix="fas" role="img" viewBox="0 0 576 512"
       xmlns="http://www.w3.org/2000/svg">
    <path
        d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"
        fill="currentColor"></path>
  </svg>
</ng-template>
