import {KolibriEntity} from '../kolibri-entity';

export enum DatabaseAction {
  inserted = 'inserted',
  updated = 'updated',
  skipped = 'skipped',
  ignored = 'ignored',
  faulty = 'faulty',
  rejected = 'rejected'
}

export class ImportRow extends KolibriEntity {
  public importRunId?: string;
  public importMapId?: string;
  public importControllerId?: string;
  public payload?: Record<string, any>;
  public targetEntity?: string;
  public targetId?: string;
  public databaseAction?: DatabaseAction;
  public expiresAt?: string;
  public importComment?: string;
  public repAttributeValue?: string;
  public repAttributeValueForTarget?: string;
}
