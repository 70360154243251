import {AfterContentInit, Component, NgZone} from '@angular/core';
import {AuthService, SettingsService} from '../../../../api';
import {FormComponent} from '../../../../ui';

@Component({
  selector: 'login-multi-auth',
  templateUrl: './multi-auth.component.html',
  styleUrls: ['./multi-auth.component.scss', '../form.scss']
})
export class MultiAuthComponent extends FormComponent implements AfterContentInit {
  public token: string;
  public showMultiAuthErrorMessage: boolean = false;
  public resendToken: boolean;

  public constructor(private authService: AuthService, private zone: NgZone, public settingsService: SettingsService) {
    super();
    this.showSuccessMessage = false;
    this.resendToken = (window as any).resendToken;
  }

  public ngAfterContentInit(): void {
    // well there is no real done, so trigger some time later...
    const subscription = this.zone.onStable.subscribe(() => {
      subscription.unsubscribe();
      this.focusFirstEmptyInput();
    });
  }

  public async doSave(): Promise<boolean> {
    try {
      await this.authService.validateToken(this.token);
      window.location.href = '/';
      return true;
    } catch (e: any) {
      switch (e.status) {
        case 429:
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant('MultiAuth.MaximumRetriesReached'),
            summary: '',
            key: 'growl'
          });
          break;
        case 412:
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant('Error.InvalidToken'),
            summary: '',
            key: 'growl'
          });
          break;
        default:
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant('Error.UnexpectedMessage'),
            summary: '',
            key: 'growl'
          });
          console.error(e);
          break;
      }

      return false;
    }
  }

  public async resend(): Promise<boolean> {
    try {
      await this.authService.resendToken();
      this.messageService.add({
        severity: 'success',
        detail: this.translateService.instant('MultiAuth.NewTokenGenerated'),
        summary: '',
        key: 'growl'
      });
      return true;
    } catch (e: any) {
      if (e.status === 500) {
        this.showMultiAuthErrorMessage = true;
      }
      return false;
    }
  }

  /**
   * perform a logout and redirect to auth url
   * nobody logout will be redirected to login form
   */
  public async logout(url: string = 'logout'): Promise<void> {
    await this.authService.logout();
    window.location.href = `/auth/${url}`;
  }
}
