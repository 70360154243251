import {EntityModel} from '../../service/entities/entity-model';

import {BundleKeyGenerator} from '../../util/bundle-key-generator';

import {Utility} from '../../util/utility';

import {AbstractRuntimeModelService} from '../coded/abstract-runtime-model.service';


export class ModelDotWalker {
  public finalName: string;
  public path: string = '';
  public invalid: boolean;
  public entity: EntityModel;

  public constructor(public name: string, public startEntity: EntityModel, private modelService: AbstractRuntimeModelService,
                     private localized: boolean = false,
                     private substring: boolean = localized, private language: string = 'de') {
    this.entity = startEntity;
  }

  public invoke(): ModelDotWalker {
    // user performed a dot walk
    this.finalName = '';
    const paths: string[] = [];

    if (this.name != null && this.name.includes('.')) {
      const split = this.name.split('.');
      for (let i = 0; i < split.length; i++) {
        const s = split[i];
        // last work is used for the actual autocomplete
        if (i === split.length - 1) {
          this.finalName = s;
          break;
        }

        // if type is json or something similar use this field as finalName
        const att = this.entity.allAttributes.filter(attribute => {
          const key = BundleKeyGenerator.fieldToKey(attribute, this.entity);
          const value: string = this.localized && key !== global.translate.instant(key, this.language) ? global.translate.instant(key,
            this.language) : attribute.name;
          // only use substring search when not already terminated with an dot
          return Utility.matches(value, s, !this.substring || i < (split.length - 1));
        });

        if (att.length) {
          const firstAtt = att[0];
          if (AbstractRuntimeModelService.OBJECT_TYPES.includes(firstAtt.typeId)) {
            this.finalName = firstAtt.name;
            // stay like it was
            this.invalid = true;
            break;
          }
        }

        // otherwise search for requested relation
        // get all function
        const relation1 = this.entity.allRelations.filter(relation => {
          const key = BundleKeyGenerator.fieldToKey(relation, this.entity);
          // @ts-ignore
          const value: string = this.localized && key !== global.translate.instant(key, this.language) ? global.translate.instant(key,
            this.language) : relation.name;
          // only use substring search when not already terminated with an dot
          return Utility.matches(value, s, !this.substring || i < (split.length - 1));
        });

        if (!relation1.length) {
          this.invalid = true;
          return this;
        }

        // if not present critical error in model.xml
        paths.push(relation1[0].name);
        this.entity = this.modelService.getEntityByType(relation1[0]);
      }
    } else {
      this.finalName = this.name;
    }
    this.path = paths.join('.');
    return this;
  }
}
