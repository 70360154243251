import {_} from '@wspsoft/underscore';
import {AbstractCriteriaFactory} from '../../criteria/abstract-criteria-factory';
import {CriteriaOperator} from '../../criteria/criteria-operator';
import {Variable} from '../../model/database/variable/variable';
import {Field} from '../../model/response/field';
import {DateType} from '../../model/xml/display-transformation';
import {FieldTypes} from '../../model/xml/section-types';
import {Utility} from '../../util/utility';
import {AbstractModelService} from '../coded/abstract-model.service';
import {EntityModel} from '../entities/entity-model';
import {AdditionalRenderData} from '../types/additional-render-data.type';

export abstract class AbstractVariableTypeService {
  private readonly DATE_ONLY_DISPLAY_TRANSFORMATION = '16f444be-5a41-4b81-8e84-1575fce72477';
  private readonly DATE_TIME_DISPLAY_TRANSFORMATION = 'f0a3cb68-34be-4bbf-8c66-206b9b1965ce';
  private readonly TIME_ONLY_DISPLAY_TRANSFORMATION = 'ba389d84-f920-4830-abf4-22c2fbb4f6df';
  private readonly SHOW_NO_SEPARATOR_DISPLAY_TRANSFORMATION = '4f605633-0c51-4bc8-b479-0b0150a30c23';

  protected constructor(protected modelService: AbstractModelService) {
  }

  public abstract get currentLang(): string;

  /**
   * convert variable to additional field
   */
  public async convertVariableToField(entityMeta: EntityModel, criteriaFactory: AbstractCriteriaFactory): Promise<Field[]> {
    const filterableAttributes = this.getVariableAttributes(entityMeta);

    const result = [];
    if (filterableAttributes.length) {
      try {
        // now load all variables matching the requested entities
        const query = criteriaFactory.get<Variable>('Variable');
        const variables = await query
          .and('class', CriteriaOperator.IN, _.flatMap(filterableAttributes, 'entities'))
          .getResults();
        await Utility.bulkDotWalk(variables, 'entity', (query as any).entityService);

        result.push(...(this.getVariableFields(variables, filterableAttributes)));
      } catch (e: any) {
        console.error(`Could not load variables for entity ${entityMeta.name}`, e);
      }
    }

    return result;
  }

  public getVariableAttributes(entityMeta: EntityModel, onlyFilterable: boolean = true): { name: string; entities: string[] }[] {
    // look for filterable variable attributes
    return entityMeta.allAttributes.filter(
      attribute => onlyFilterable ? attribute.variableFilterable : this.modelService.getTypeName(attribute) === 'VariablesData').map(attribute => ({
      name: attribute.name,
      entities: _.isEmpty(attribute.variableSources)
        ? [this.modelService.getEntity(attribute.entityId).name, entityMeta.name]
        : attribute.variableSources
    }));
  }

  public getVariableFields(variables: Variable[], variableAttributes: { name: string; entities: string[] }[]): Field[] {
    const result: Field[] = [];
    for (const variable of variables) {
      // get all variableAttributes that have the entity in entities (and its descendants if flag is true) as the variable in class
      for (const foundAttribute of _.filter(variableAttributes, (variableAttribute) =>
        variableAttribute.entities.includes(variable.class))) {

        const duplicateText = _.find(variables,
          v => v.questionText?.[this.currentLang] === variable.questionText?.[this.currentLang]
            && v.entityId !== variable.entityId) ? ` (${variable.entity.representativeString})` : '';
        const field: Partial<Field> = {
          label: `${variable.questionText?.[this.currentLang]}${duplicateText}`,
          name: `${foundAttribute.name}.${variable.name}.value`,
          entityClass: variable.uiType === FieldTypes.ENTITY_AUTOCOMPLETE_SECTION_FIELD ? 'Relation' : 'Attribute',
          availableForQueryBuilder: true,
        };
        if (Utility.isRelation(field)) {
          field.targetId = variable.target;
        } else {
          field.typeId = this.getTypeOfVariable(variable);
          // add additional type info for Display transformations
          if (field.typeId === 'Date') {
            field.displayTransformId = this.getDisplayTransformationByDateType(variable.dateType);
          } else if (field.typeId === 'Number') {
            field.displayTransformId = this.getDisplayTransformationByNumberVariable(variable);
          }
        }

        result.push(field);
      }
    }
    return result;
  }

  public getRenderDataFromVariable(value: Variable): AdditionalRenderData {
    return {
      dateType: value.dateType ? value.dateType : undefined,
      showSeparator: !_.isNullOrEmpty(value.showSeparator) ? value.showSeparator : undefined,
      decimalPlaces: value.decimalPlaces ? value.decimalPlaces : undefined,
      symbol: value.symbol ? value.symbol : undefined,
    };
  }

  private getDisplayTransformationByDateType(dateType: DateType): string {
    switch (dateType) {
      case 'date':
      default:
        return this.DATE_ONLY_DISPLAY_TRANSFORMATION;
      case 'dateTime':
        return this.DATE_TIME_DISPLAY_TRANSFORMATION;
      case 'time':
        return this.TIME_ONLY_DISPLAY_TRANSFORMATION;
    }
  }

  /**
   * convert ui type to matching type
   */
  private getTypeOfVariable({uiType, targetForChoice}: Variable): string {
    switch (uiType) {
      case FieldTypes.ENTITY_AUTOCOMPLETE_SECTION_FIELD:
        return null;
      case FieldTypes.CHOICE_OPTION_SECTION_FIELD:
      case FieldTypes.CHOICE_AUTO_COMPLETE_SECTION_FIELD:
        return targetForChoice;
      case FieldTypes.DATE_PICKER_SECTION_FIELD:
        return 'Date';
      case FieldTypes.INPUT_NUMBER_SECTION_FIELD:
        return 'Number';
      case FieldTypes.CHECKBOX_SECTION_FIELD:
        return 'Boolean';
      default:
        return 'String';
    }
  }

  private getDisplayTransformationByNumberVariable(variable: Variable): string {
    if (!variable.showSeparator) {
      return this.SHOW_NO_SEPARATOR_DISPLAY_TRANSFORMATION;
    }
    return null;
  }
}
