import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractVariableTypeService} from '@wspsoft/frontend-backend-common';
import {ModelService} from '../coded/model.service';

@Injectable()
export class VariableTypeService extends AbstractVariableTypeService {
  public constructor(modelService: ModelService, private translateService: TranslateService) {
    super(modelService);
  }

  public get currentLang(): string {
    return this.translateService.currentLang;
  }
}
