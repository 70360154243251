import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Choice, ChoiceValue} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ModelService, ModelTranslationService} from '../../../../../../api';
import {KolibriScriptExecutor} from '../../../../../../script-api';
import {MultiSelect} from '../multi-select';

@Component({
  selector: 'ui-choice-multiselect',
  templateUrl: './choice-multi-select-input.component.html',
  styleUrls: ['./choice-multi-select-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChoiceMultiSelectInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoiceMultiSelectInputComponent extends MultiSelect<ChoiceValue[]> {
  @Input()
  public choiceId: string;

  public constructor(private modelTranslationService: ModelTranslationService, private modelService: ModelService,
                     private kolibriScriptExecutor: KolibriScriptExecutor) {
    super();
  }

  public async narrowDownOptions(event: any): Promise<void> {
    await this.setupOptions(event.filter);
    event.cb();
  }

  protected async setupOptions(filterString?: string): Promise<void> {
    const choiceMeta: Choice = this.modelService.getChoice(this.choiceId);
    for (const value of choiceMeta.values) {
      value.label = this.modelTranslationService.translateChoiceValue(value, this.choiceId);
    }
    const choiceValues = _.sortBy(choiceMeta.values, ['order', 'label']).map(value => ({
      value,
      label: value.label,
      icon: value.icon,
      title: value.label
    }));

    const options = await this.kolibriScriptExecutor.evaluateFilterScript(this.tableId, this.filterScript, `ChoiceMultiSelect:${this.field}`, undefined,
      choiceValues, filterString).result;

    // extract entries from the current value to put them at the front
    const valueArray = [];
    for (const value of this.value ?? []) {
      valueArray.push(..._.remove(options, (option) => option.value.id === value.id));
    }

    this.options = [...valueArray, ...options];
  }
}
