<figure [ngClass]="{'one-avatar--margin':margin, 'one-avatar--big':big, 'one-avatar--empty': !imageId}" class="one-avatar"
        style="--oneAvatarColorBG:{{data.color}};--oneAvatarColorFG:{{foregroundColor}};--oneAvatarDimension:{{dimension}}px">
  <img *ngIf="imageId" [alt]="imageId" [defaultImage]="lazy ? environment.serverAdd + staticPath + '/images/digits.gif' : srcUrl" [lazyLoad]="srcUrl" alt=""
       class="one-avatar__content">
  <div *ngIf="!imageId && data.initials" class="one-initials">
    {{ data.initials }}
  </div>
  <i *ngIf="!imageId && !data.initials" class="one-avatar__content fas fa-fw {{icon}}"></i>
</figure>
<div *ngIf="showStatus" class="one-avatar--status">
  <div class="fa-stack">
    <i [ngClass]="{
    'one-avatar--active': !idle,
    'one-avatar--away': away,
    'one-avatar--inactive': idle
    }" class="fas fa-fw fa-circle fa-stack-1x"></i>
    <i *ngIf="typing" class="fas fa-fw fa-cog fa-spin fa-stack-1x one-avatar--action"></i>
    <i *ngIf="away" class="fas fa-fw fa-minus fa-stack-1x one-avatar--action"></i>
  </div>
</div>
