import {ChangeDetectionStrategy, Component, forwardRef, Host, Input, OnChanges, Optional, SimpleChanges, SkipSelf} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Choice, ChoiceValue, Utility} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {SelectItemGroup} from 'primeng/api';
import {ModelService, ModelTranslationService} from '../../../../../../api';
import {ChoiceValueConverterService} from '../../../converter/choice-value-converter.service';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-choice-autocomplete',
  templateUrl: './choice-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './choice-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChoiceAutocompleteInputComponent),
    multi: true
  }, ChoiceValueConverterService],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoiceAutocompleteInputComponent extends AutoComplete<ChoiceValue | ChoiceValue[]> implements OnChanges {
  @Input()
  public choiceId: string;
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public clearable: boolean = true;
  @Input()
  public search: (result: ChoiceValue[], queryString: string) => MaybePromise<ChoiceValue[]>;
  private choiceMeta: Choice;

  public constructor(private modelTranslationService: ModelTranslationService, private modelService: ModelService,
                     private choiceValueConverterService: ChoiceValueConverterService) {
    super();
    this.converter = choiceValueConverterService;
  }

  public get suggestions(): (ChoiceValue | ChoiceValue[])[] | SelectItemGroup[] {
    return super.suggestions;
  }

  public set suggestions(value: (ChoiceValue | ChoiceValue[])[] | SelectItemGroup[]) {

    if (!_.isNullOrEmpty(this.value)) {
      // remove all values from suggestions that are already inserted
      for (const val of this.multiple ? this.value as ChoiceValue[] : [this.value]) {
        value = _.filter(value as ChoiceValue[], x => x !== val);
      }
      // Move the current selected value to the top of the suggestion list if we are in single mode
      if (!this.multiple) {
        value.unshift(this.value as SelectItemGroup & (ChoiceValue | ChoiceValue[]));
      }
    }
    super.suggestions = value;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.choiceId) {
      this.choiceMeta = this.modelService.getChoice(this.choiceId);
      this.choiceValueConverterService.init(this.choiceMeta);
    }
  }

  public onComplete($event: any): void {
    this.lastQuery = $event.query;
    const choiceValues = this.choiceMeta.values;
    for (const value of choiceValues) {
      value.label = this.modelTranslationService.translateChoiceValue(value, this.choiceId);
    }

    const values = this.filterValues(choiceValues, $event);

    // apply custom filter function
    if (this.search) {
      const result = this.search(values, $event.query);
      _.maybeAwait(result, x => {
        this.suggestions = this.filterValues(x.map(y => typeof y === 'string' ? this.converter.getAsObject(y) : y), $event);
        this.cdr.detectChanges();
      });
    } else {
      this.suggestions = values;
      this.cdr.detectChanges();
    }

    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
  }

  private filterValues(choiceValues: ChoiceValue[], $event: any): ChoiceValue[] {
    if (this.multiple && !_.isEmpty(this.value)) {
      // filter out the current selected Values from the suggestions
      choiceValues = choiceValues.filter(value => !(this.value as ChoiceValue[]).includes(value));
    }
    return _.sortBy(choiceValues.filter(value => Utility.matches(value.label, $event.query, undefined, this.searchOperator)),
      ['order', 'label']);
  }
}
